import React from 'react';
import {validate} from "uuid";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {TransitionWrapper} from "../TransitionWrapper";
import PromptBinding from "../PromptBinding";

class PlayerProgressCode extends React.Component {
  state = {
    code: null,
    link: null,
  };

  render () {
    const uuid = this.props.uuid ?? "";
    const prompts = this.props.prompts;

    let board = "";

    if (validate(uuid)) {
      const link = window.location + uuid;
      const copied = <div className={"transition-flow-appear progress-code-copy-message"}>Copied</div>;

      const prompt_copy_code_key = 'progress-code-value-id';
      const prompt_copy_code_content = [
        <div>{prompts.get('player-code-copy-1')}</div>,
        <span>{prompts.get('player-code-copy-2')}</span>,
      ];

      const copy_code_content = (
        <CopyToClipboard text={uuid} onCopy={() => this.setState({code: true, link: false})}>
          <div className={"progress-code-content"}>
            Your progress code:<br/>
            <div className={"progress-code-content-value"}>{uuid}</div>
            <TransitionWrapper content={copied} in_transition={this.state.code} timeout={500} class_name={"transition-flow"}/>
          </div>
        </CopyToClipboard>
      );

      const prompt_copy_code = (
        <PromptBinding
          prompts={prompts}
          prompt_key={prompt_copy_code_key}
          prompt_content={prompt_copy_code_content}
          content={copy_code_content}
          delay={500}
        />
      );

      const prompt_copy_link_key = 'progress-code-link-id';
      const prompt_copy_link_content = [
        <div>{prompts.get('player-code-copy-link')}</div>,
        <span className={"progress-code-copy-link-prompt"}>{link}</span>
      ];

      const copy_link_content = (
        <CopyToClipboard text={link} onCopy={() => this.setState({code: false, link: true})}>
          <div className={"progress-code-copy-link"}>
            Copy link to your progress game
            <TransitionWrapper content={copied} in_transition={this.state.link} timeout={500} class_name={"transition-flow"}/>
          </div>
        </CopyToClipboard>
      );

      const prompt_copy_link = (
        <PromptBinding
          prompts={prompts}
          prompt_key={prompt_copy_link_key}
          prompt_content={prompt_copy_link_content}
          content={copy_link_content}
          delay={500}
        />
      );

      board = <div className={"gate-body-progress-code"}>
        {prompt_copy_code}
        {prompt_copy_link}
      </div>;
    }

    return board;
  }
}

export default PlayerProgressCode;
