import React from 'react';
import PromptBinding from "../PromptBinding";

class PortablePlayerStageProperty extends React.Component {
  render () {
    const code = this.props.code;
    const level = this.props.level;
    const status = this.props.status;
    const prompts = this.props.prompts;

    const prompt_key = "player-stage-prompt-key";
    const prompt_content = prompts.get("stage-" + code);

    const content = (
      <div className={"p-player-stage"}>
        <div className={"p-player-stage-image p-player-stage-image--" + code}>
          <div className={"p-player-stage-level"}>{level}<span>LV</span></div>
          <div className={"p-player-stage-status p-player-stage-status--" + status}/>
        </div>
      </div>
    );

    return <PromptBinding
      prompts={prompts}
      prompt_key={prompt_key}
      prompt_content={prompt_content}
      content={content}
    />;
  }
}

export default PortablePlayerStageProperty;
