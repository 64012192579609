import React from 'react';
import CopyrightLine from "../Components/General/CopyrightLine";
import DataManager from "../../Engine/Managers/DataManager";
import SectorController from "../Sectors/SectorController";
import Wall from "../Sectors/Builds/Wall";
import Post from "../Sectors/Upgrades/Post";
import Tunnel from "../Sectors/Builds/Tunnel";
import Farm from "../Sectors/Upgrades/Farm";
import Storage from "../Sectors/Builds/Storage";
import Special from "../Sectors/Upgrades/Special";
import ProductionSectorsSwitcher from "../Components/Switchers/ProductionSectorsSwitcher";
import WorkersResourceCounter from "../Components/Resources/General/ResourceCounters/WorkersResourceCounter";
import RawResourceCounter from "../Components/Resources/General/ResourceCounters/RawResourceCounter";
import NectarResourceCounter from "../Components/Resources/General/ResourceCounters/NectarResourceCounter";
import SpecialistsResourceCounter from "../Components/Resources/General/ResourceCounters/SpecialistsResourceCounter";
import StorageProgressCounter from "../Components/Resources/General/ResourceCounters/StorageProgressCounter";
import QueenProgressCounter from "../Components/Resources/General/ResourceCounters/QueenProgressCounter";
import LimitResourceCounter from "../Components/Resources/General/ResourceCounters/LimitResourceCounter";
import ObserversResourceCounter from "../Components/Resources/General/ResourceCounters/ObserversResourceCounter";
import PortableProductionProperty from "../Components/Properties/PortableProductionProperty";
import PortableStageFeatureProperty from "../Components/Properties/PortableStageFeatureProperty";
import PlayerStageBoard from "../Components/PlayerStageBoard";
import PortablePlayerLevelButton from "../Components/Properties/PortablePlayerLevelButton";

class StagePlayFlowDesk extends React.Component {
  render () {
    const version = DataManager.getVersion();
    const app = this.props.app;

    const expandedPropertiesClass = (app.state['process']['is_open_player_board'] ?? false)
      ? 'p-stage-properties--expanded'
      : 'p-stage-properties--collapsed';

    return (
      <div className={"stage-play-flow-desk"}>
        <div className={"p-zone-header"}>
          <div className={"p-logo"}/>
        </div>
        <div className={"p-zone-stage-properties " + expandedPropertiesClass}>
          <div className={"p-zone-stage-properties-edge-left"}>
            <PortablePlayerLevelButton app={app}/>
          </div>
          <div className={"p-zone-stage-properties-edge-middle"}/>
          <div className={"p-zone-stage-properties-edge-right"}>
            <PortableProductionProperty app={app}/>
            <PortableStageFeatureProperty app={app}/>
          </div>
        </div>
        <PlayerStageBoard app={app}/>
        <div className={"p-zone-stage-play-wood"}>
          <ProductionSectorsSwitcher app={app}/>
        </div>
        <div className={"p-zone-stage-play-resources"}>
          <div className={"p-zone-stage-play-resources-edge-left-left"}>
            <WorkersResourceCounter app={app}/>
          </div>
          <div className={"p-zone-stage-play-resources-edge-middle-left"}>
            <RawResourceCounter app={app}/>
          </div>
          <div className={"p-zone-stage-play-resources-edge-middle-right"}>
            <NectarResourceCounter app={app}/>
          </div>
          <div className={"p-zone-stage-play-resources-edge-right-right"}>
            <SpecialistsResourceCounter app={app}/>
          </div>
        </div>
        <div className={"p-zone-stage-play-buildings"}>
          <div className={"p-zone-stage-play-buildings-edge-left"}>
            <SectorController app={app} sectors={[Wall, Post]}/>
          </div>
          <div className={"p-zone-stage-play-buildings-edge-middle"}>
            <SectorController app={app} sectors={[Tunnel, Farm]}/>
          </div>
          <div className={"p-zone-stage-play-buildings-edge-right"}>
            <SectorController app={app} sectors={[Storage, Special]}/>
          </div>
        </div>
        <div className={"p-zone-stage-play-buildings-effects"}>
          <div className={"p-zone-stage-play-buildings-effects-edge-left"}>
            <ObserversResourceCounter app={app}/>
          </div>
          <div className={"p-zone-stage-play-buildings-effects-edge-middle"}>
            <LimitResourceCounter app={app}/>
          </div>
          <div className={"p-zone-stage-play-buildings-effects-edge-right"}>
            <StorageProgressCounter app={app}/>
            <QueenProgressCounter app={app}/>
          </div>
        </div>
        <div className={"p-zone-footer"}>
          <div className={"p-ground"}>
            <CopyrightLine/> | v{version} (test)
          </div>
        </div>
      </div>
    );
  }
}

export default StagePlayFlowDesk;
