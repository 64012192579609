import React from 'react';
import PortablePlayerStageProperty from "./Properties/PortablePlayerStageProperty";
import PortablePlayerLevelUpProperty from "./Properties/PortablePlayerLevelUpProperty";
import {QueenFound} from "./QueenFound";

class StageEndProgressResultBoard extends React.Component {
  render () {
    const app = this.props.app;

    const prompts = app.prompts;
    const player = app.player;

    const stage = player.stage;

    return (
      <div className={"p-stage-end-progress-result"}>
        <QueenFound prompts={prompts}/>
        <div className={"p-stage-end-player-result"}>
          <div className={"p-stage-end-player-result-stage"}>
            <PortablePlayerStageProperty
              code={stage["code"]}
              level={stage["level"]}
              status={stage["status"]}
              prompts={prompts}
            />
          </div>
          <div className={"p-stage-end-player-result-level-up"}>
            <PortablePlayerLevelUpProperty
              player={player}
              prompts={prompts}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default StageEndProgressResultBoard;
