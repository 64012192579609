import React from 'react';
import SkillsChoiceBoard from "../Components/Skills/SkillsChoiceBoard";
import StageExitBoard from "../Components/Stages/StageExitBoard";
import AchievementsBoard from "../Components/Achievements/AchievementsBoard";
import StageEndMessagesStaticBoard from "../Components/StageEndMessagesStaticBoard";

class StageEndStaticDesk extends React.Component {
  render () {
    const app = this.props.app;

    return (
      <div className={"stage-finish-desk"}>
        <div className={"head-line"}/>
        <div className={"queen-found"}/>
        <StageEndMessagesStaticBoard app={app}/>
        <AchievementsBoard app={app}/>
        <div className={"arrow-line"}/>
        <SkillsChoiceBoard app={app}/>
        <StageExitBoard app={app}/>
        <div className={"ground-line"}/>
        <div className={"ground-fill"}/>
      </div>
    );
  }
}

export default StageEndStaticDesk;
