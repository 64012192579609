import React from 'react';
import Regular from "../../../../../Engine/Processing/Regular";
import Practical from "../../../../../Engine/Practical";
import Codes from "../../../../../Engine/Codes";
import PortableSpecialProgressCounterView from "../../Portable/PortableSpecialProgressCounterView";
import DesktopSpecialProgressCounterView from "../../Desktop/DesktopSpecialProgressCounterView";

class QueenProgressCounter extends React.Component {
  render () {
    const app = this.props.app;
    const prompts = app.prompts;

    const chance = Practical.number(Regular.getSearchChance(app) * 100, 0);
    const acceleration = Practical.number(Regular.getSearchAcceleration(app) * 100, 0);

    const is_visible = app.state['storage'] >= 100;

    const View = app.mode === Codes.modes.portable
      ? PortableSpecialProgressCounterView
      : DesktopSpecialProgressCounterView

    return <View
      chance={chance}
      acceleration={acceleration}
      prompts={prompts}
      is_visible={is_visible}
    />
  }
}

export default QueenProgressCounter;