import DefaultSector from "../DefaultSector";
import Codes from "../../../Engine/Codes";

class Special extends DefaultSector {
    parameters () {
        return {
            name: "special",
            type: Codes.types.upgrade,
            resources: {
                building: "storage",
                upgrade: "special",
            },
            need_clicks: 10,
            preview: {
                storage: 100,
            },
            requirements: {
                raw: this.getClosureBuildingCost("special", 10),
                nectar: 3,
                specialists: 1,
            },
        }
    }
}

export default Special;