import React from 'react';

class PortablePlayerLevelButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      is_open: false,
    }

    this.onMouseEnter = this.onMouseEnter.bind(this);
  }

  onMouseEnter () {
    const is_open = !this.state.is_open;

    this.props.app.onPlayerBoard(is_open);

    this.setState({
      is_open: is_open
    });
  };

  render () {
    const app = this.props.app;
    const player = app.player;

    return (
      <div className={"p-stage-player-level-button-board"} onClick={this.onMouseEnter}>Lv. {player.level}</div>
    );
  }
}

export default PortablePlayerLevelButton;

