import React from 'react';
import CopyrightLine from "../Components/General/CopyrightLine";
import DataManager from "../../Engine/Managers/DataManager";

class StageGroundStaticDesk extends React.Component {
  render () {
    return (
      <div className={"stage-ground-desk"}>
        <div className={"ground-info-line"}>
          <CopyrightLine/>
          <div className={"dash"}>|</div>
          <div className={"ver"}>
            <a className={"patreon-link-vrs"}
               href={"https://www.patreon.com/gunzhin"}
               target="_blank"
               rel="noopener noreferrer"
            >
              v{DataManager.getVersion()} (test)
            </a>
          </div>
        </div>
        <div className={"ground-filler-line"}/>
      </div>
    );
  }
}

export default StageGroundStaticDesk;
