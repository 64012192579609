import SkillsSummarizer from "./SkillsSummarizer";
import Codes from "../Codes";
import Practical from "../Practical";

class SkillsHandlers {

  static handlerCollectorNeedClicks (player, need_clicks) {
    return SkillsHandlers._handlerNeedClicks(player, Codes.skills.collector, need_clicks);
  }

  static handlerSeekerNeedClicks (player, need_clicks) {
    return SkillsHandlers._handlerNeedClicks(player, Codes.skills.seeker, need_clicks);
  }

  static handlerBuilderNeedClicks (player, need_clicks) {
    return SkillsHandlers._handlerNeedClicks(player, Codes.skills.builder, need_clicks);
  }

  static handlerUpgraderNeedClicks (player, need_clicks) {
    return SkillsHandlers._handlerNeedClicks(player, Codes.skills.upgrader, need_clicks);
  }

  static handlerRecruiterNeedClicks (player, need_clicks) {
    return SkillsHandlers._handlerNeedClicks(player, Codes.skills.recruiter, need_clicks);
  }

  static handlerTrainerNeedClicks (player, need_clicks) {
    return SkillsHandlers._handlerNeedClicks(player, Codes.skills.trainer, need_clicks);
  }


  static handlerCollectorRawCount (player, result = {}) {
    return SkillsHandlers._handlerIncreaseByChance(player, Codes.skills.collector, result, Codes.resources.raw);
  }

  static handlerInviterObserversCount (player, result = {}) {
    return SkillsHandlers._handlerIncreaseByChance(player, Codes.skills.inviter, result, Codes.resources.observers);
  }

  static handlerSeekerNectarCount (player, result = {}) {
    return SkillsHandlers._handlerIncreaseByChance(player, Codes.skills.seeker, result, Codes.resources.nectar);
  }

  static handlerPromoterSpecialistsCount (player, result = {}) {
    return SkillsHandlers._handlerIncreaseByChance(player, Codes.skills.promoter, result, Codes.resources.specialists);
  }

  static handlerTrainerSpecialistsCount (player, result) {
    return SkillsHandlers._handlerIncreaseByChance(player, Codes.skills.trainer, result, Codes.resources.specialists);
  }


  static handlerInviterObserversGrowth (player, growth) {
    return growth + (SkillsSummarizer.getPlayerSkillParam(player, Codes.skills.inviter, "increase") ?? 0);
  }

  static handlerPromoterSpecialistsGrowth (player) {
    return SkillsSummarizer.getPlayerSkillParam(player, Codes.skills.promoter, "increase") ?? 0;
  }

  static handlerChiefWorkersGrowth (player) {
    return SkillsSummarizer.getPlayerSkillParam(player, Codes.skills.chief, "special") ?? 0;
  }

  static handlerChiefWorkersIncrease (player, increase) {
    return increase + (SkillsSummarizer.getPlayerSkillParam(player, Codes.skills.chief, "increase") ?? 0);
  }


  static handlerBuilderRawEconomy (player, result = {}) {
    const chance = SkillsSummarizer.getPlayerSkillParam(player, Codes.skills.builder, "chance");
    const chance_special = SkillsSummarizer.getPlayerSkillParam(player, Codes.skills.builder, "special");

    let raw = result[Codes.resources.raw];

    if (Math.random() <= chance_special) {
      raw = Practical.number(raw * 0.50, 0);
    } else if (Math.random() <= chance) {
      raw = Practical.number(raw * 0.75, 0);
    }

    result[Codes.resources.raw] = raw;

    return result;
  }

  static handlerUpgraderNectarEconomy (player, result = {}) {
    const chance = SkillsSummarizer.getPlayerSkillParam(player, Codes.skills.upgrader, "chance");
    const chance_special = SkillsSummarizer.getPlayerSkillParam(player, Codes.skills.upgrader, "special");

    let nectar = result[Codes.resources.nectar];

    if (Math.random() <= chance_special) {
      nectar += 2;
    } else if (Math.random() <= chance) {
      nectar += 1;
    }

    result[Codes.resources.nectar] = nectar;

    return result;
  }

  static handlerRecruiterObserversRequirement (player, requirements) {
    let player_requirement = SkillsSummarizer.getPlayerSkillParam(player, Codes.skills.recruiter, "requirement");

    if (player_requirement > 0) {
      const origin = requirements[Codes.resources.observers];

      if (origin < 0) {
        player_requirement *= -1;
      }

      requirements[Codes.resources.observers] -= player_requirement;
    }

    return requirements;
  }


  static _handlerIncreaseByChance (player, skill, pack, resource) {
    const chance = SkillsSummarizer.getPlayerSkillParam(player, skill, "chance");

    if (chance > 0 && Math.random() <= chance) {
      pack[resource] = (pack[resource] ?? 0) + 1;
    }

    return pack;
  }

  static _handlerNeedClicks(player, skill, count) {
    const player_increase = SkillsSummarizer.getPlayerSkillParam(player, skill, "increase");

    if (player_increase > 0) {
      count -= Practical.number(count * player_increase, 0);
    }

    return count;
  }
}

export default SkillsHandlers;
