import React from 'react';
import SkillCardDesktopItem from "./SkillCardDesktopItem";

class SkillsChoiceDesktopView extends React.Component {
  render () {
    const items = this.props.items;
    const onSkill = this.props.onSkill;

    let render = [];

    for (let n in items) {
      const item = items[n];

      render.push(
          <SkillCardDesktopItem
            key={n}
            code={item.code}
            level={item.level}
            layer={item.layer}
            is_available={item.is_available}
            onSkill={onSkill}
          />
      );
    }

    const empty_cards_count = 4 - items.length;

    if (empty_cards_count > 0) {
      for (let n = 0; n < empty_cards_count; n++) {
        render.push(
          <div className={"skill-block"}>
            <div className={"skill-card skill-card-empty"}/>
          </div>
        );
      }
    }

    return (
      <div className={"skill-choice"}>
        {render}
      </div>
    );
  }
}

export default SkillsChoiceDesktopView;
