import React from 'react';
import ProgressScale from '../../Components/Progress/ProgressScale.js';
import DesktopResourcesRequirements from "../../Components/Resources/Desktop/DesktopResourcesRequirements";
import BuildersDesktopCounter from "../../Components/Counters/BuildersDesktopCounter";
import Codes from "../../../Engine/Codes";
import DesktopResourcesTransition from "../../Components/Resources/Desktop/DesktopResourcesTransition";

class DefaultDesktopView extends React.Component {
  constructor(props) {
    super(props);

    this.name = this.props.name;
    this.type = this.props.type;
    this.resource = this.props.resource;
    this.preview = this.props.preview;
    this.prompts = this.props.prompts;
    this.switcher_type = this.props.switcher_type ?? "active";
  }

  render () {
    let resource_flow_values = this.props.result;

    const requirements = this.props.requirements;

    const is_active = this.props.active;

    const need_clicks = this.props.need_clicks;
    const current_clicks = this.props.current_clicks;
    const onClick = this.props.onClick;

    const buildings_count = this.props.buildings_count;
    const upgrades_count = this.props.upgrades_count;

    let classNameSector = "sector sector-" + this.name;

    if (!is_active) {
      classNameSector += " sector-disable";
    }

    const switcher = (this.props.onSwitch !== null)
      ? <div className={"prev-sector-switch-" + this.switcher_type} onClick={this.props.onSwitch}/>
      : <div className={"prev-sector-switch"}/>;

    const sector_counters = [Codes.types.building, Codes.types.upgrade].includes(this.type)
      ? <BuildersDesktopCounter
          buildings={buildings_count}
          upgrades={upgrades_count}
          current={this.type}
      />
      : '';

    let sector_specific_class = "sector-specific sector-specific-" + this.name;

    if (!is_active) {
      sector_specific_class += " sector-specific-disable";
    }

    let sector_specific = <div className={sector_specific_class} onClick={onClick}/>

    const progress_size = (this.name === "wood")
      ? "wide"
      : "regular";

    let sector_progress = (
      <ProgressScale size={progress_size} max={need_clicks} current={current_clicks}/>
    );

    let resources_requirements = Object.keys(requirements).length > 0
      ? <DesktopResourcesRequirements resources={requirements}/>
      : null;

    if ([Codes.types.building, Codes.types.upgrade, Codes.types.procreate].includes(this.type)) {
      if (resource_flow_values.hasOwnProperty(this.resource)) {
        delete resource_flow_values[this.resource];
      }
    }

    let resources_changes = (
      <DesktopResourcesTransition resources={resource_flow_values} transition={this.props.show_result}/>
    );

    return (
        <div className={classNameSector}>
          {switcher}
          {sector_counters}
          {sector_specific}
          {sector_progress}
          {resources_requirements}
          {resources_changes}
        </div>
      );
  }
}

export default DefaultDesktopView;
