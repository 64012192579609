import React from 'react';
import {Tooltip} from "react-tooltip";

class PromptBinding extends React.Component {
  constructor(props) {
    super(props);

    this.prompt_key = props.prompt_key;
    this.prompt_content = props.prompt_content;
    this.place = props.place ?? "top";
    this.delay = props.delay ?? 50;
    this.onPrompt = props.prompts.onPrompt();

    this.onClick = this.onClick.bind(this);
    this.onClose = this.onClose.bind(this);
  }

  onClick () {
    const update = this.props.prompts.actual() !== this.prompt_key
      ? this.prompt_key
      : null;

    this.onPrompt(update);
  }

  onClose () {
    this.onPrompt(null);
  }

  render () {
    const content = this.props.content;
    const is_open = this.props.prompts.actual() === this.prompt_key;

    return (
      <div data-tooltip-id={this.prompt_key} onClick={this.onClick} onMouseLeave={this.onClose}>
        {content}
        <Tooltip
          className={"tip-wrapper"}
          id={this.prompt_key}
          delayShow={this.delay}
          place={this.place}
          isOpen={is_open}
        >
          <span>{this.prompt_content}</span>
        </Tooltip>
      </div>
    );
  }
}

export default PromptBinding;