import React from 'react';
import PromptBinding from "../../PromptBinding";

class PortableObserversResourceCounterView extends React.Component {
  render () {
    const count = this.props.count;
    const increase = this.props.increase;
    const is_visible = this.props.is_visible;
    const prompts = this.props.prompts;

    const prompt_key = 'resource-observers-prompt-id';
    const prompt_content = prompts.get('wall');

    const content = (
      <div className={"p-resource-observers"}>
        <div className={"p-resource-observers-count"}>
          {count}
        </div>
        <div className={"p-resource-observers-increase"}>
          {increase}
          <span className={"p-resource-increase-measure"}>/s</span>
        </div>
      </div>
    );

    return is_visible
      ? <PromptBinding
          prompts={prompts}
          prompt_key={prompt_key}
          prompt_content={prompt_content}
          content={content}
          place={'top-start'}
        />
      : ""
  }
}

export default PortableObserversResourceCounterView;