import React from 'react';

class PortableStageFeatureProperty extends React.Component {
  render () {
    return (
      <div className={"p-stage-feature-property"}/>
    );
  }
}

export default PortableStageFeatureProperty;
