import React from 'react';
import TitleLineTile from "../Components/General/TitleLineTile";
import MessagesBoard from "../Components/Messages/MessagesBoard";
import PlayerBoard from "../Components/Player/PlayerBoard";
import StageBoard from "../Components/Stages/StageBoard";

class StageStatusStaticDesk extends React.Component {
  render () {
    const app = this.props.app;

    return (
      <div className={"main-desk"} key={"main-desk-key"}>
        <div className={"title-desk"} key={"title-desk-key"}>
          <TitleLineTile/>
        </div>
        <div className={"player-desk"} key={"player-desk-key"}>
          <div className={"player-board-padding"}>
            <PlayerBoard app={app}/>
          </div>
        </div>
        <div className={"stage-desk"} key={"stage-desk-key"}>
          <StageBoard app={app}/>
        </div>
        <div className={"messages-desk"} key={"messages-desk-key"}>
          <MessagesBoard app={app}/>
        </div>
      </div>
    );
  }
}

export default StageStatusStaticDesk;
