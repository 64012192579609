import DefaultSector from "../DefaultSector";
import Codes from "../../../Engine/Codes";

class Storage extends DefaultSector {
    parameters () {
        return {
            name: "storage",
            type: Codes.types.building,
            resources: {
                building: "storage",
                upgrade: "special",
            },
            need_clicks: 30,
            preview: {
                tunnels: 10,
            },
            requirements: {
                raw: this.getClosureBuildingCost("storage", 18),
            },
        }
    }
}

export default Storage;