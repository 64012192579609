import React from 'react';
import DataManager from "../../../Engine/Managers/DataManager";
import Practical from "../../../Engine/Practical";
import PromptBinding from "../PromptBinding";

class AchievementDesktopItem extends React.Component {
  render () {
    const prompts = this.props.prompts;

    const code = this.props.code;
    const status_class_postfix = (this.props.is_retrieved === true)
      ? '-done'
      : '-process';

    const class_name = "achievement-item-image achievement-item-image-" + code + status_class_postfix;

    const prompt_key = "achievement-" + code + "-prompt-id";
    const title = DataManager.getDescriptionValue(DataManager.DSC_TYPE_ACHIEVEMENTS, code);

    const prompt_content = Practical.concatList(
      DataManager.handleContent("%%" + title + ":TITLE%%"),
      DataManager.getAchievementDefinition(code)
    );

    const content = <div className={class_name}/>;

    return (
      <div className={"achievement-item"}>
        <PromptBinding
          prompts={prompts}
          prompt_key={prompt_key}
          prompt_content={prompt_content}
          content={content}
          delay={500}
        />
      </div>
    );
  }
}

export default AchievementDesktopItem;