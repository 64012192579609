import Practical from "../../Engine/Practical";
import PromptBinding from "./PromptBinding";

export function QueenFound (self) {
  const prompts = self.prompts;

  const prompt_content = Practical.concatList(
    prompts.get('gameplay-finish-general-1'), <br/>, <br/>,
    prompts.get('gameplay-finish-general-2'), <br/>, <br/>,
    prompts.get('gameplay-finish-general-3'),
  );

  const prompt_key = "queen-found-prompt-id";
  const content = <div className={"p-stage-end-queen"}/>;

  return <PromptBinding
    prompts={prompts}
    prompt_key={prompt_key}
    prompt_content={prompt_content}
    content={content}
  />
}