import React from 'react';
import MessageTile from "./MessageTile";
import Notificator from "../../../Engine/Notificator";
import {TransitionWrapper} from "../TransitionWrapper";

class MessagesBoard extends React.Component {
  render () {
    const state = this.props.app.state;

    let tiles = [];

    const messages = Notificator.getCurrentMessages(state).reverse();
    const has_new = Notificator.getStateMessagesHasNew(state);

    for (let key in messages) {
      let message = messages[key];

      let tile = <MessageTile text={message} key={"message-key-" + key}/>;

      if (tiles.length <= 0) {
        tile = <TransitionWrapper content={tile} in_transition={has_new} timeout={500} class_name={"message-tile-flow"}/>
      }

      tiles.push(tile);

      if (tiles.length >= Notificator.LIST_MAX_COUNT) {
        break;
      }
    }

    return (
      <div className={"messages-board"}>
        {tiles}
      </div>
    );
  }
}

export default MessagesBoard;
