import React from 'react';
import ProgressBinding from "../Progress/ProgressBinding";
import LegacyFeature from "../../../Engine/Features/LegacyFeature";
import PromptBinding from "../PromptBinding";

class PlayerLegacyBoard extends React.Component {
  render () {
    const action = this.props.action;
    const player = this.props.player;
    const key = "player_legacy_choice";
    const hidden = this.props.hidden ?? false
    const level = hidden ? 10 : player.level
    const prompts = this.props.prompts;

    const prompt_key = key + "_prompt_key";
    const legacy_prompt_content = prompts.get('legacy');
    const legacy_definition_content = LegacyFeature.getDefinition(level);

    const onResult = {
      "func": action,
      "params": level
    };

    const prompt_content = [
      <div key={key + '-span-def'}>{legacy_prompt_content}</div>,
      <span key={key + '-span-skill'}>{legacy_definition_content}</span>,
    ];

    const content = <div className={"gate-body-legacy-block"}/>;

    const prompt_binding_content = (
      <PromptBinding
        prompts={prompts}
        prompt_key={prompt_key}
        prompt_content={prompt_content}
        content={content}
        delay={500}
      />
    );

    return (hidden)
      ? <ProgressBinding
        key={key + "-progress"}
        class={"gate-body-legacy-block"}
        type={"hidden"}
        content={""}
        onResult={onResult}
        disposable={true}
        scale={20}
      />
      : <ProgressBinding
        key={key + "-progress"}
        class={"gate-body-legacy"}
        type={"wide"}
        content={prompt_binding_content}
        onResult={onResult}
        disposable={true}
        color={"choice"}
      />
  }
}

export default PlayerLegacyBoard;
