import React from 'react';

class PortableResourcesRequirements extends React.Component {
  render () {
    let result = [];
    let resources = this.props.resources ?? {};

    for (let key in resources) {
      let count = resources[key];
      let resource_class = "";

      if (key === "raw") {
        resource_class = "p-resource-flow-requirement-raw-" + (count > 9 ? "long" : "short");
      } else {
        resource_class = "p-resource-flow-requirement-" + key;
      }

      result.push(
        <div className={"p-resource-flow-requirement " + resource_class} key={key}>
          <div className={"p-resource-flow-count"}>{count}</div>
        </div>
      );
    }

    return (
      <div className={"p-resource-flow-static"}>
        {result}
      </div>
    );
  }
}

export default PortableResourcesRequirements;
