import React from 'react';
import DataManager from "../../../Engine/Managers/DataManager";
import AchievementsWarden from "../../../Engine/AchievementsWarden";
import Practical from "../../../Engine/Practical";
import PromptBinding from "../PromptBinding";

class PortablePlayerLevelUpProperty extends React.Component {
  render () {
    const player = this.props.player;
    const prompts = this.props.prompts;

    const levels = player.result.growth;
    const achievement = player.result.achievement;

    const items = AchievementsWarden.composeActual(player);

    let retrieved = [];

    for (let code in items) {
      if (player.achievements.includes(code)) {
        retrieved.push(code);
      }
    }

    const achievements_is_done = (retrieved.length === 3);

    let achievement_message;

    if (achievement) {
      const name = DataManager.getDescriptionValue(DataManager.DSC_TYPE_ACHIEVEMENTS, achievement);
      achievement_message = DataManager.getDescriptionValue(DataManager.DSC_TYPE_GENERAL, "achievement-earned", {"NAME": name});
    } else if (achievements_is_done) {
      achievement_message = DataManager.getDescriptionValue(DataManager.DSC_TYPE_GENERAL, "achievement-earned-all");
    } else {
      achievement_message = DataManager.getDescriptionValue(DataManager.DSC_TYPE_GENERAL, "achievement-earned-none");
    }

    const prompt_key = "gameplay-level-up-prompt-id";
    const prompt_content = Practical.concatList(
      prompts.get("gameplay-skills"), <br/>, <br/>, achievement_message
    );

    const content = (
      <div className={"p-player-level-up"}>
        <div className={"p-player-level-up-image"}/>
        <div className={"p-player-level-up-value p-player-level-up-value--" + levels}/>
      </div>
    );

    return <PromptBinding
      prompts={prompts}
      prompt_key={prompt_key}
      prompt_content={prompt_content}
      content={content}
    />
  }
}

export default PortablePlayerLevelUpProperty;
