import React from 'react';
import Regular from "../../../../../Engine/Processing/Regular";
import Practical from "../../../../../Engine/Practical";
import Codes from "../../../../../Engine/Codes";
import DesktopObserversResourceCounterView from "../../Desktop/DesktopObserversResourceCounterView";
import PortableObserversResourceCounterView from "../../Portable/PortableObserversResourceCounterView";

class ObserversResourceCounter extends React.Component {
  render () {
    const app = this.props.app;
    const prompts = app.prompts;

    const count = app.state['observers'];
    const walls_increase = Regular.efficiency(app, 'observers', 'walls');
    const posts_decrease = Regular.efficiency(app, 'observers', 'posts');
    const increase = Practical.number(walls_increase + posts_decrease).toFixed(2);
    const is_visible = app.state['walls'] > 0;

    const View = app.mode === Codes.modes.portable
      ? PortableObserversResourceCounterView
      : DesktopObserversResourceCounterView;

    return <View
      count={count}
      increase={increase}
      is_visible={is_visible}
      prompts={prompts}
    />
  }
}

export default ObserversResourceCounter;