import React from 'react';

class BlocksSwitcher extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selected: 0,
        };

        this.blocks = this.props.blocks ?? [];
        this.class = this.props.class ?? "p-block-switcher";

        this.onClickNext = this.onClickNext.bind(this);
        this.onClickPrev = this.onClickPrev.bind(this);
    }

    getArrowClass (type, order, count) {
        const is_disabled = (type === 'prev' && order === 0) || (type === 'next' && order === count - 1);

        return 'p-block-switcher-arrow p-block-switcher-arrow-' + type + (is_disabled ? ' p-block-switcher-arrow--disabled' : '');
    }

    onClickNext () {
        const count = this.blocks.length;
        const current = this.state.selected ?? 0;
        const next = current < count - 1 ? current + 1 : current;

        this.setState({"selected": next});
    }

    onClickPrev () {
        const current = this.state.selected ?? 0;
        const prev = current > 0 ? current - 1 : current;

        this.setState({"selected": prev});
    }

    render () {
        const order = this.state.selected;
        const count = this.blocks.length;
        const block = this.blocks[order] ?? null;

        let points = [];

        for (let num in this.blocks) {
          points.push(
            <div className={'p-block-switcher-point p-block-switcher-point' + (Number(num) === order ? '--active' : '--wait')}/>
          );
        }

        return (
          <div className={this.class}>
              <div className={this.getArrowClass('prev', order, count)} onClick={this.onClickPrev}/>
              <div className={this.getArrowClass('next', order, count)} onClick={this.onClickNext}/>
              <div className={"p-block-switcher-points"}>
                {points}
              </div>
              {block}
          </div>
        );
    }
}

export default BlocksSwitcher;
