import ResourceCounter from "./ResourceCounter";
import Regular from "../../../../../Engine/Processing/Regular";
import Practical from "../../../../../Engine/Practical";

class RawResourceCounter extends ResourceCounter {
  getResource () {
    return 'raw';
  }

  getIncrease () {
    const app = this.props.app;

    const value = (
        Regular.efficiency(app, this.getResource(), 'workers') +
        Regular.efficiencyCleaning(app)
    ).toFixed(3);

    return value >= 10
      ? Practical.number(value, 2).toFixed(2)
      : Practical.number(value, 3).toFixed(3);
  }

  getVisibleCondition() {
    const app = this.props.app;

    return (
      app.state.raw > 0 ||
      app.state.walls > 0
    );
  }
}

export default RawResourceCounter;