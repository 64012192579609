import { useRef } from "react";
import {CSSTransition} from "react-transition-group";

export function TransitionWrapper (self) {
  const nodeRef = useRef(null);

  const content = self.content;
  const in_transition = self.in_transition;
  const timeout = self.timeout;
  const class_name = self.class_name;

  return (
    <CSSTransition nodeRef={nodeRef} in={in_transition} timeout={timeout} classNames={class_name}>
      <div ref={nodeRef}>
        {content}
      </div>
    </CSSTransition>
  );
}