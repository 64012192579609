import React from 'react';
import AchievementDesktopItem from "./AchievementDesktopItem";
import DataManager from "../../../Engine/Managers/DataManager";
import PromptBinding from "../PromptBinding";

class AchievementsDesktopView extends React.Component {
  render () {
    const achievements = this.props.achievements;
    const retrieved = this.props.retrieved;
    const current = this.props.current;
    const prompts = this.props.prompts;

    const achievements_is_done = (retrieved.length === 3);
    const status = achievements_is_done ? "done" : "process";

    let items = [];

    for (let code in achievements) {
      const is_retrieved = retrieved.includes(code);

      items.push(
        <AchievementDesktopItem code={code} is_retrieved={is_retrieved} prompts={prompts}/>
      );
    }

    let message;

    if (current) {
      const name = DataManager.getDescriptionValue(DataManager.DSC_TYPE_ACHIEVEMENTS, current);
      message = DataManager.getDescriptionValue(DataManager.DSC_TYPE_GENERAL, "achievement-earned", {"NAME": name});
    } else if (achievements_is_done) {
      message = DataManager.getDescriptionValue(DataManager.DSC_TYPE_GENERAL, "achievement-earned-all");
    } else {
      message = DataManager.getDescriptionValue(DataManager.DSC_TYPE_GENERAL, "achievement-earned-none");
    }

    const prompt_key = "tip-achievements-status";
    const prompt_content = prompts.get("gameplay-achievements");

    const content = <div className={"achievements-status-" + status}/>;

    return (
      <div className={"achievements-board"}>
        <div className={"achievements-images"}>{items}</div>
        <div className={"achievements-message"}>{message}</div>
        <PromptBinding
          prompts={prompts}
          prompt_key={prompt_key}
          prompt_content={prompt_content}
          content={content}
          delay={500}
        />
      </div>
    );
  }
}

export default AchievementsDesktopView;