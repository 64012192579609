import React from 'react';
import Codes from "../../../Engine/Codes";

class BuildersDesktopCounter extends React.Component {
  render () {
    let buildings_count = this.props.buildings;
    let upgrades_count = this.props.upgrades;
    let current = this.props.current;

    let sector_counter_builds_class = (upgrades_count > 0)
      ? "sector-counter-builds"
      : "sector-counter-builds-single";

    let sector_counter_upgrades_class = "sector-counter-upgrades";

    let sector_counter_builds_selected_class = (current === Codes.types.building)
      ? 'sector-counter-line-selected'
      : 'sector-counter-line-empty';

    let sector_counter_builds = (
      <div key={this.name + '-building-group'} className={'sector-counter-block'}>
        <div key={this.name + '-building-line'} className={sector_counter_builds_selected_class}/>
        <div key={this.name + '-building-value'} className={sector_counter_builds_class}>
          {buildings_count}
        </div>
      </div>
    );

    let counters = [];
    counters.push(sector_counter_builds);

    if (upgrades_count) {
      let sector_counter_upgrades_selected_class = (current === Codes.types.upgrade)
        ? 'sector-counter-line-selected'
        : 'sector-counter-line-empty';

      let sector_counter_upgrades = (
        <div key={this.name + '-upgrade-group'} className={'sector-counter-block'}>
          <div key={this.name + '-upgrade-line'} className={sector_counter_upgrades_selected_class}/>
          <div key={this.name + '-upgrade-value'} className={sector_counter_upgrades_class}>
            {upgrades_count}
          </div>
        </div>
      );

      counters.push(sector_counter_upgrades);
    }

    return (
      <div className={"sector-counters"}>{counters}</div>
    );
  }
}

export default BuildersDesktopCounter;
