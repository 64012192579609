import React from 'react';
import ProgressScale from "../Progress/ProgressScale";
import PromptBinding from "../PromptBinding";

class StageSpecialTile extends React.Component {
  render () {
    const name = this.props.name;
    const color = this.props.color ?? "dark";
    const progress_max = this.props.progress_max ?? 0;
    const progress_current = this.props.progress_current ?? 0;
    const prompts = this.props.prompts;

    const prompt_key = "processing-tile-prompt-" + name;
    const prompt_content = prompts.get('process');

    const content = (
      <div className={"stage-special-tile"}>
        <div className={"stage-special-image-" + name}/>
        <div className={"process-progress"}>
          <ProgressScale size={"mini"} color={color} max={progress_max} current={progress_current}/>
        </div>
      </div>
    );

    return (
      <PromptBinding
        prompts={prompts}
        prompt_key={prompt_key}
        prompt_content={prompt_content}
        content={content}
        delay={500}
      />
    );
  }
}

export default StageSpecialTile;
