import React from 'react';

class PortablePlayerCanvasProperty extends React.Component {
  render () {
    return (
      <div className={"p-player-canvas"}/>
    );
  }
}

export default PortablePlayerCanvasProperty;
