import React from 'react';
import Player from "../Engine/Entities/Player";
import GateFlowDesk from "../Display/Desks/GateFlowDesk";
import StagePlayFlowDesk from "../Display/Desks/StagePlayFlowDesk";
import StageEndFlowDesk from "../Display/Desks/StageEndFlowDesk";

class PortableController extends React.Component {
    render () {
        let app = this.props.app;

        const desks = [];
        const player_status = app.player.status;

        switch (player_status) {
            case Player.STATUS_GATE:
                desks.push(<GateFlowDesk app={app}/>);
                break;

            case Player.STATUS_PLAY_STAGE:
                desks.push(<StagePlayFlowDesk app={app}/>);
                break;

            case Player.STATUS_FINISH_STAGE:
                desks.push(<StageEndFlowDesk app={app}/>);
                break;

            default:
                break;
        }

        return (
            <div className={'window-portable'}>
                <div className={'window-portable-wrapper'}>
                    {desks}
                </div>
            </div>
        );
    }
}

export default PortableController;
