import React from 'react';
import AchievementPortableItem from "./AchievementPortableItem";

class AchievementsPortableView extends React.Component {
  render () {
    const achievements = this.props.achievements;
    const retrieved = this.props.retrieved;
    const prompts = this.props.prompts;

    let items = []

    for (let code in achievements) {
      const is_retrieved = retrieved.includes(code);

      items.push(
        <AchievementPortableItem code={code} is_retrieved={is_retrieved} prompts={prompts}/>
      );
    }

    return (
      <div className={"p-stage-end-achievements"}>
        {items}
      </div>
    )
  }
}

export default AchievementsPortableView;