import React from 'react';
import CopyrightLine from "../Components/General/CopyrightLine";
import DataManager from "../../Engine/Managers/DataManager";
import AchievementsBoard from "../Components/Achievements/AchievementsBoard";
import StageEndProgressResultBoard from "../Components/StageEndProgressResultBoard";
import SkillsChoiceBoard from "../Components/Skills/SkillsChoiceBoard";
import PortableProductionProperty from "../Components/Properties/PortableProductionProperty";
import PortableStageFeatureProperty from "../Components/Properties/PortableStageFeatureProperty";
import PlayerStageBoard from "../Components/PlayerStageBoard";
import PortablePlayerLevelButton from "../Components/Properties/PortablePlayerLevelButton";
import StageExitBoard from "../Components/Stages/StageExitBoard";

class StageEndFlowDesk extends React.Component {
  render () {
    const version = DataManager.getVersion();
    const app = this.props.app;

    const expandedPropertiesClass = (app.state['process']['is_open_player_board'] ?? false)
      ? 'p-stage-properties--expanded'
      : 'p-stage-properties--collapsed';

    return (
        <div className={"stage-end-flow-desk"}>
          <div className={"p-zone-header"}>
            <div className={"p-logo"}/>
          </div>
          <div className={"p-zone-stage-properties " + expandedPropertiesClass}>
            <div className={"p-zone-stage-properties-edge-left"}>
              <PortablePlayerLevelButton app={app}/>
            </div>
            <div className={"p-zone-stage-properties-edge-middle"}/>
            <div className={"p-zone-stage-properties-edge-right"}>
              <PortableProductionProperty app={app}/>
              <PortableStageFeatureProperty app={app}/>
            </div>
          </div>
          <PlayerStageBoard app={app}/>
          <div className={"p-zone-stage-end-progress-result"}>
            <StageEndProgressResultBoard app={app}/>
          </div>
          <div className={"p-zone-stage-end-achievements"}>
            <AchievementsBoard app={app}/>
          </div>
          <div className={"p-zone-stage-end-skills"}>
            <SkillsChoiceBoard app={app}/>
            <StageExitBoard app={app}/>
          </div>
          <div className={"p-zone-footer"}>
            <div className={"p-ground"}>
              <CopyrightLine/> | v{version}
            </div>
          </div>
        </div>
    );
  }
}

export default StageEndFlowDesk;
