import Codes from "../Codes";
import Practical from "../Practical";

class StagesHandlers {

  static handlerRawEfficiency (player, efficiency) {
    if (player.stage.code === Codes.stages.few_raw) {
      efficiency = Practical.number(efficiency * 0.7, 4);
    }

    return efficiency;
  }

  static handlerObserversGrowth (player, growth) {
    if (player.stage.code === Codes.stages.few_observers) {
      growth = Practical.number(growth / 2, 4);
    }

    return growth;
  }

  static handlerCleaningEfficiency (player, efficiency) {
    if (player.stage.code === Codes.stages.high_cleaning) {
      efficiency *= 2;
    }

    return efficiency;
  }
}

export default StagesHandlers;
