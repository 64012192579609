import React from 'react';
import PlayerSkillTile from "./PlayerSkillTile";
import PlayerFeatureTile from "./PlayerFeatureTile";
import Codes from "../../../Engine/Codes";

class PlayerSkills extends React.Component {
  render () {
    const mode = this.props.mode ?? Codes.modes.desktop;
    const prompts = this.props.prompts;
    const features = this.props.features;
    const skills = this.props.skills;
    let skills_panel = [];

    for (let code in features) {
      const level = features[code];

      skills_panel.push(
        <PlayerFeatureTile
          prompts={prompts}
          code={code}
          level={level}
        />
      );
    }

    for (let code in skills) {
      const level = skills[code];

      skills_panel.push(
        <PlayerSkillTile
          prompts={prompts}
          code={code}
          level={level}
        />
      );
    }

    const class_name = mode === Codes.modes.portable
      ? "p-player-skills"
      : "player-skills";

    return (
      <div className={class_name}>
        {skills_panel}
      </div>
    );
  }
}

export default PlayerSkills;
