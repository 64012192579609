import React from 'react';

class DesktopLimitResourceCounterView extends React.Component {
  render () {
    const population = this.props.population;
    const limit = this.props.limit;
    const is_visible = this.props.is_visible;

    return is_visible
      ? <div className={"resource-limit"}>
            {population} / {limit}
        </div>
      : ""
  }
}

export default DesktopLimitResourceCounterView;