import React from 'react';
import PromptBinding from "../PromptBinding";

class StageCardTile extends React.Component {
  render () {
    const size = this.props.size ?? "small";
    const type = this.props.type ?? "static";
    const stage = this.props.stage;
    const level = this.props.level;
    const prompts = this.props.prompts;
    const status = this.props.status;

    const is_disabled = status === "disabled";
    const level_word = "lv";
    const key = "stage-" + stage + "-" + size;

    let class_name_postfix;

    let content;

    if (size === "medium") {
      const class_name_status = "stage-static-medium-" + status;
      class_name_postfix = " " + class_name_status;
    }

    if (type === "static") {
      const prompt_key = key + "-prompt-key";
      const prompt_content = !is_disabled
        ? prompts.get("stage-" + stage.code)
        : prompts.get("empty");

      const inner_content = (
        <div className={"stage-" + type + "-" + size + " stage-static-choice" + class_name_postfix} key={key}>
          <div className={"stage-image stage-image-" + stage} key={key + '-i'}/>
          <div className={"stage-level"} key={key + '-lvl'}>{level}<span>{level_word}</span></div>
          <div className={"stage-status stage-status-" + status} key={key + '-st'}/>
        </div>
      );

      content = (
        <PromptBinding
          prompts={prompts}
          prompt_key={prompt_key}
          prompt_content={prompt_content}
          content={inner_content}
          delay={500}
        />
      );
    } else {
      content = (
        <div className={"stage-static-medium stage-flow-choice" + class_name_postfix} key={key}>
          <div className={"stage-image stage-image-" + stage} key={key + '-i'}/>
          <div className={"stage-level"} key={key + '-lvl'}>{level}<span>{level_word}</span></div>
          <div className={"stage-status stage-status-" + status} key={key + '-st'}/>
        </div>
      );
    }

    return content;
  }
}

export default StageCardTile;
