import React from 'react';
import PortablePlayerStageProperty from "./Properties/PortablePlayerStageProperty";
import PlayerSkills from "./Player/PlayerSkills";
import Notificator from "../../Engine/Notificator";
import PortablePlayerCanvasProperty from "./Properties/PortablePlayerCanvasProperty";
import Player from "../../Engine/Entities/Player";

class PlayerStageBoard extends React.Component {
  render () {
    const app = this.props.app;
    const player = app.player;
    const stage = player.stage;

    const features = player.features;
    const skills = player.skills;

    const prompts = app.prompts;
    const messages_prompt = prompts.get('click-to-explanation');

    const visibleClass = (app.state['process']['is_open_player_board'] ?? false)
      ? 'p-stage-player-board--visible'
      : 'p-stage-player-board--hidden';

    const messages = Notificator.getCurrentMessages(app.state).reverse();

    let messages_tiles = [];

    messages_tiles.push(
      <div className={"p-stage-player-board-messages-prompt"}>
        <div className={"p-stage-player-board-messages-prompt-sign"}>?!</div>
        <div className={"p-stage-player-board-messages-prompt-text"}>{messages_prompt}</div>
      </div>
    );

    for (let key in messages) {
      let message = messages[key];

      messages_tiles.push(
        <div className={"p-stage-player-board-messages-item"}>
          <div className={"p-stage-player-board-messages-item-sign"}>❖</div>
          <div className={"p-stage-player-board-messages-item-text"}>{message}</div>
        </div>
      );
    }

    const player_stage_property = player.status === Player.STATUS_PLAY_STAGE
      ? <PortablePlayerStageProperty
        code={stage["code"]}
        level={stage["level"]}
        status={stage["status"]}
        prompts={prompts}
      />
      : <PortablePlayerCanvasProperty/>

    return <div className={"p-stage-player-board " + visibleClass}>
      <div className={"p-stage-player-board-properties"}>
        <div className={"p-stage-player-board-properties-stage"}>
          {player_stage_property}
        </div>
        <div className={"p-stage-player-board-properties-skills"}>
          <PlayerSkills
            mode={app.mode}
            features={features}
            skills={skills}
            prompts={prompts}
          />
        </div>
      </div>
      <div className={"p-stage-player-board-messages"}>
        {messages_tiles}
      </div>
    </div>
  }
}

export default PlayerStageBoard;

