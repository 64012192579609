import React from 'react';
import StagesManager from "../../../../Engine/Managers/StagesManager";
import Codes from "../../../../Engine/Codes";
import StageChoiceDesktopView from "./StageChoiceDesktopView";
import StageChoicePortableView from "./StageChoicePortableView";

class StageChoiceBoard extends React.Component {
  render () {
    const app = this.props.app;
    const player = app.player;
    const prompts = app.prompts;
    const onStage = app.onStage;

    const stages = StagesManager.getOpenStages(player.level);

    const items = [];

    for (let key in stages) {
      items[key] = stages[key].toJson();
    }

    const View = app.mode === Codes.modes.portable
      ? StageChoicePortableView
      : StageChoiceDesktopView;

    return <View
      stages={items}
      onStage={onStage}
      prompts={prompts}
    />;
  }
}

export default StageChoiceBoard;