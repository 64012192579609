import Practical from '../Practical.js';
import LegacyFeature from "../Features/LegacyFeature";
import Codes from "../Codes";
import SkillsHandlers from "./SkillsHandlers";
import StagesHandlers from "./StagesHandlers";

class Regular {
  static efficiencyWorkersWithBuildings (app) {
    let buildings = [
      Codes.resources.tunnels,
      Codes.resources.farms,
    ];

    let increase = 1;

    for (let key in buildings) {
      let building = buildings[key];

      let count = app.state[building];
      let efficiency = app.settings.performance[building]["efficiency"] ?? 0;

      if (count > 0 && efficiency !== 0) {
        increase += (Math.floor(count / 25) * efficiency);
      }
    }

    increase = SkillsHandlers.handlerChiefWorkersIncrease(app.player, increase);

    return Practical.number(increase, 4);
  }

  static getSearchChance(app) {
    let grades = app.settings.performance.search.chance_grades;
    let count = app.state.special;
    let chance = 0;

    for (let requirement in grades) {
      if (count >= requirement) {
        chance = grades[requirement];
      } else {
        break;
      }
    }

    return chance;
  }

  static getSearchAcceleration(app) {
    let step = app.settings.performance.search.acceleration_step;
    let count = app.state.special;

    return Practical.number(count * step, 2);
  }

  static production (app, state) {
    let production = app.settings.production;

    const has_available_limit = this.getAvailablePopulationLimit(state) > 0;

    for (let maker in production) {
      let resources = production[maker];

      for (let resource in resources) {
        let increase = 0.00;

        if (resource === Codes.resources.workers) {
          const player_increase = SkillsHandlers.handlerChiefWorkersGrowth(app.player);

          increase = has_available_limit > 0
            ? this.efficiency(app, resource, maker) + player_increase
            : 0;
        } else if (resource === Codes.resources.specialists) {
          increase = has_available_limit > 0
            ? this.efficiencySpecialistsGrowth(app)
            : 0;
        } else {
          increase = this.efficiency(app, resource, maker);
        }

        if (increase !== 0) {
          let count = app.state[resource];
          let stack = this.getStackState(app, resource) + increase;

          while (stack >= 1) {
            count++;
            stack--;

            if (resource === Codes.resources.specialists) {
              state[Codes.resources.workers]--;
            }
          }

          state[resource] = count;
          state['stacks'][resource] = stack;
        }
      }
    }

    // TODO: Optimize
    let cleaning = this.efficiencyCleaning(app);

    if (cleaning !== 0) {
      let resource = Codes.resources.raw;
      let count = app.state[resource];
      let stack = this.getStackState(app, resource) + cleaning;

      while (stack >= 1) {
        count++;
        stack--;
      }

      while (stack <= -1 && count > 0) {
        count--;
        stack++;
      }

      state[resource] = count;
      state['stacks'][resource] = stack;
    }

    return state;
  }

  static efficiency (app, resource_type, resource_maker) {
    let maker_count = app.state[resource_maker];

    let resource_production = app.settings.production[resource_maker][resource_type] ?? 0;
    let efficiency = Practical.number(resource_production * maker_count, 4);

    if (resource_maker === Codes.resources.workers) {
      if (resource_type === Codes.resources.raw) {
        efficiency = StagesHandlers.handlerRawEfficiency(app.player, efficiency);
        efficiency *= this.efficiencyWorkersWithBuildings(app);
      }
    } else if (resource_maker === Codes.resources.walls) {
      efficiency = StagesHandlers.handlerObserversGrowth(app.player, efficiency);
      efficiency = SkillsHandlers.handlerInviterObserversGrowth(app.player, efficiency);

    } else if (resource_maker === Codes.resources.posts) {
      if (resource_type === Codes.resources.observers) {
        efficiency = StagesHandlers.handlerObserversGrowth(app.player, efficiency);
      }
    }

    if (
        (resource_maker === Codes.resources.posts && resource_type === Codes.resources.workers) ||
        (resource_maker === Codes.resources.farms && resource_type === Codes.resources.nectar)
    ) {
      if (app.state.storage >= 75) {
        efficiency *= (1 + app.settings.performance[resource_maker]["acceleration"]);
      }
    }

    const legacy = app.legacy.getPlayerState(app.state);

    if (legacy !== null && legacy.status === LegacyFeature.STATUS_WORK) {
      efficiency *= 2;
    }

    return Practical.number(efficiency, 4);
  }

  static efficiencySpecialistsGrowth(app) {
    const growth = app.settings.performance.specialists.growth; // 0.0001
    const acceleration = app.settings.production.storage.specialists; // 0.02
    const workers = app.state[Codes.resources.workers];
    const storage = app.state[Codes.resources.storage];
    const player_increase = SkillsHandlers.handlerPromoterSpecialistsGrowth(app.player);

    let efficiency = (storage > 0)
      ? (growth * workers) * (1 + acceleration * storage) + player_increase
      : 0;

    const legacy = app.legacy.getPlayerState(app.state);

    if (legacy !== null && legacy.status === LegacyFeature.STATUS_WORK) {
      efficiency *= 2;
    }

    return Practical.number(efficiency, 4);
  }

  static efficiencyCleaning(app) {
    const efficiency = StagesHandlers.handlerCleaningEfficiency(app.player, app.settings.performance.cleaning.efficiency);

    const buildings = [
        Codes.resources.walls,
        Codes.resources.tunnels,
        Codes.resources.storage,
        Codes.resources.posts,
        Codes.resources.farms,
        Codes.resources.special,
    ];

    let decrease = 0.00;

    for (let key in buildings) {
      let building = buildings[key];
      let buildings_count = app.state[building];

      if (buildings_count > 0) {
        decrease -= (buildings_count * efficiency);
      }
    }

    return Practical.number(decrease, 4);
  }

  static getCurrentCost (cost, count) {
    let increase = Math.floor(count / 25) * 0.65;
    let resource = cost * (1 + increase);

    return Practical.number(resource, 0);
  }

  static getStackState (app, type) {
    let stacks = app.state.stacks;

    return stacks.hasOwnProperty(type)
      ? stacks[type]
      : 0;
  }

  static getPopulationValue(state) {
    return Practical.number(state[Codes.resources.workers] + state[Codes.resources.specialists], 0);
  }

  static getPopulationLimit (state) {
    return Practical.number((state[Codes.resources.tunnels] * 4) + 4, 0);
  }

  static getAvailablePopulationLimit(state) {
    let value = this.getPopulationValue(state);
    let limit = this.getPopulationLimit(state);

    return Practical.number(limit - value, 0);
  }
}

export default Regular;
