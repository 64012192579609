import React from 'react';
import BlocksSwitcher from "./BlocksSwitcher";
import WoodRaw from "../../Sectors/Wood/WoodRaw";
import WoodNectar from "../../Sectors/Wood/WoodNectar";
import WoodSearch from "../../Sectors/Wood/WoodSearch";
import SectorController from "../../Sectors/SectorController";
import Training from "../../Sectors/Work/Training";
import Recruitment from "../../Sectors/Work/Recruitment";

class ProductionSectorsSwitcher extends BlocksSwitcher {
    constructor(props) {
      super(props);

      this.state = {
        selected: 1,
      };

      const app = props.app;

      this.blocks = [
        <SectorController app={app} sectors={[Recruitment]} key={"recruitment-controller"}/>,
        <SectorController app={app} sectors={[WoodRaw, WoodNectar, WoodSearch]} key={"wood-controller"}/>,
        <SectorController app={app} sectors={[Training]} key={"training-controller"}/>
      ];
    }

    render () {
      const order = this.state.selected;
      const block = this.blocks[order] ?? null;

      let prev;
      let next;

      if (order === 0) {
        prev = <div className={"p-block-switcher-wood p-block-switcher-wood-dummy-left"}/>;
        next = <div className={"p-block-switcher-wood p-block-switcher-wood-next"} onClick={this.onClickNext}/>;
      } else if (order === 2) {
        prev = <div className={"p-block-switcher-wood p-block-switcher-wood-prev"} onClick={this.onClickPrev}/>;
        next = <div className={"p-block-switcher-wood p-block-switcher-wood-dummy-right"}/>;
      } else {
        prev = <div className={"p-block-switcher-arrow p-block-switcher-arrow-prev"} onClick={this.onClickPrev}/>;
        next = <div className={"p-block-switcher-arrow p-block-switcher-arrow-next"} onClick={this.onClickNext}/>;
      }

      return (
        <div className={"p-wood-production-slider"}>
          {prev}
          {next}
          {block}
        </div>
      );
    }
}

export default ProductionSectorsSwitcher;
