import React from 'react';
import DataManager from "../../../Engine/Managers/DataManager";
import ProgressScale from "../Progress/ProgressScale";

class SkillCardPortableItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      clicks: 0
    }

    this.max_clicks = 10;
    this.is_available = props.is_available;

    this.onClick = this.onClick.bind(this);
  }

  getContent () {
    const code = this.props.code;
    const level = this.props.level;

    const title = DataManager.getDescriptions(DataManager.DSC_TYPE_SKILLS)[code] ?? '';
    const description = DataManager.getSkillDefinition(code, level);

    return (
      <div className={"p-stage-end-skill-choice-card"}>
        <div className={"p-stage-end-skill-choice-card-image p-player-skill p-player-skill--" + code}>
          {level}
        </div>
        <div className={"p-stage-end-skill-choice-card-title"}>
          {title}
        </div>
        <div className={"p-stage-end-skill-choice-card-description"}>
          {description}
        </div>
      </div>
    )
  }

  onClick() {
    if (this.is_available) {
      let clicks = this.state.clicks + 1;

      if (clicks >= this.max_clicks) {
            clicks = 0;
            this.onResult();
        }

        this.setState({
            clicks: clicks
        })
    }
  }

  onResult () {
    this.props.onSkill(this.props.code);
  }

  render () {
    return (
      <div onMouseDown={this.onClick}>
        {this.getContent()}
        <ProgressScale size={"regular"} color={"choice"} max={this.max_clicks} current={this.state.clicks}/>
      </div>
    );
  }
}

export default SkillCardPortableItem;
