import React from 'react';
import DisableSector from "./DisableSector";

class SectorController extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: 0,
      switcher: false,
    };

    this.sectors = [];
    this.sectorNames = {};

    let app = this.props.app;
    let sectors = this.props.sectors;

    for (let order in sectors) {
      let special_class = sectors[order];
      let obj = new special_class({app: app})

      this.sectors.push(obj);
      this.sectorNames[order] = special_class;
    }

    this.onSwitch = this.onSwitch.bind(this);
  }

  getAvailableSectors () {
    let app = this.props.app;
    let sectors = this.sectors;
    let available_sectors = [];

    for (let order in sectors) {
      let special = sectors[order];
      let preview = special.getPreview();

      let is_available = true;

      for (let preview_key in preview) {
        if (app.state[preview_key] < preview[preview_key]) {
          is_available = false;
          break;
        }
      }

      if (is_available) {
        available_sectors.push(special);
      }
    }

    return available_sectors;
  }

  getCurrentSectorOrder (sectors) {
    let order = 0;
    let count = sectors.length;

    if (count > 0) {
      if (count > 1 && this.state.switcher === false) {
        this.setState({"switcher": true});
      } else if (count <= 1 && this.state.switcher === true) {
        this.setState({"switcher": false})
      }

      order = this.state.selected ?? 0;
    }

    return order;
  }

  onSwitch () {
    let sectors = this.getAvailableSectors();
    let count = sectors.length;

    let select = (count > 1)
      ? (this.state.selected + 1) % count
      : 0;

    this.setState({"selected": select});
  }

  render () {
    let app = this.props.app;
    let sectors = this.getAvailableSectors();
    let order = this.getCurrentSectorOrder(sectors);
    let sector = sectors[order] ?? null;
    let SectorBlock;

    if (sector !== null) {
      const onSwitch = this.state.switcher === true
        ? this.onSwitch
        : null;

      let Sector = this.sectorNames[order];
      SectorBlock = <Sector app={app} onResult={app.onResult} onSwitch={onSwitch}/>
    } else {
      let name = this.sectors[0].getName();
      SectorBlock = <DisableSector app={app} name={name}/>
    }

    return SectorBlock;
  }
}

export default SectorController;
