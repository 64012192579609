import React from 'react';
import ProgressScale from '../../Components/Progress/ProgressScale.js';
import Codes from "../../../Engine/Codes";
import BuildersPortableCounter from "../../Components/Counters/BuildersPortableCounter";
import PortableResourcesRequirements from "../../Components/Resources/Portable/PortableResourcesRequirements";
import PortableResourcesTransition from "../../Components/Resources/Portable/PortableResourcesTransition";

class DefaultPortableView extends React.Component {
  constructor(props) {
    super(props);

    this.name = this.props.name;
    this.type = this.props.type;
    this.resource = this.props.resource;
    this.preview = this.props.preview;
    this.prompts = this.props.prompts;
  }

  render () {
    let resource_flow_values = this.props.result;

    const requirements = this.props.requirements;

    const is_active = this.props.active;

    const need_clicks = this.props.need_clicks;
    const current_clicks = this.props.current_clicks;
    const onClick = this.props.onClick;

    const buildings_count = this.props.buildings_count;
    const upgrades_count = this.props.upgrades_count;

    const onSwitch = this.props.onSwitch;
    const switcher_type = this.props.switcher_type ?? "dummy";

    let classNameSector = "p-sector p-sector-" + this.name;

    if (!is_active) {
      classNameSector += " sector-disable";
    }

    let sector_switcher_or_count = "";

    if ([Codes.types.building, Codes.types.upgrade].includes(this.type)) {
      sector_switcher_or_count = <BuildersPortableCounter
        name={this.name}
        buildings={buildings_count}
        upgrades={upgrades_count}
        current={this.type}
        onSwitch={onSwitch}
      />;
    } else if ([Codes.types.collect].includes(this.type)) {
      sector_switcher_or_count = <div
        className={"wood-switcher-" + switcher_type}
        onClick={onSwitch}
      />;
    }

    let sector_specific_class = "p-sector-specific p-sector-specific-" + this.name;

    if (!is_active) {
      sector_specific_class += " sector-specific-disable";
    }

    let sector_specific = <div className={sector_specific_class} onClick={onClick}/>

    let progress_size;

    if (this.name === "wood") {
      progress_size = "wide";
    } else if (this.type === Codes.types.procreate) {
      progress_size = "portable-regular";
    } else {
      progress_size = "portable-small";
    }

    const progress_color = this.type === Codes.types.upgrade
      ? "violet"
      : "default";

    let sector_progress = (
      <ProgressScale size={progress_size} color={progress_color} max={need_clicks} current={current_clicks}/>
    );

    let resources_requirements = Object.keys(requirements).length > 0
      ? <PortableResourcesRequirements resources={requirements}/>
      : null;

    if ([Codes.types.building, Codes.types.upgrade, Codes.types.procreate].includes(this.type)) {
      if (resource_flow_values.hasOwnProperty(this.resource)) {
        delete resource_flow_values[this.resource];
      }
    }

    let resources_changes =
      <PortableResourcesTransition resources={resource_flow_values} transition={this.props.show_result}/>

    return (
        <div className={classNameSector}>
          {sector_switcher_or_count}
          {sector_specific}
          {sector_progress}
          {resources_requirements}
          {resources_changes}
        </div>
      );
  }
}

export default DefaultPortableView;
