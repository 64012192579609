import React from 'react';
import AchievementsWarden from "../../../Engine/AchievementsWarden";
import Codes from "../../../Engine/Codes";
import AchievementsPortableView from "./AchievementsPortableView";
import AchievementsDesktopView from "./AchievementsDesktopView";

class AchievementsBoard extends React.Component {
  render () {
    const app = this.props.app;
    const player = app.player;
    const prompts = app.prompts;
    const current = player.result.achievement;

    const items = AchievementsWarden.composeActual(player);

    let retrieved = [];

    for (let code in items) {
        if (player.achievements.includes(code)) {
          retrieved.push(code);
        }
    }

    const View = app.mode === Codes.modes.portable
      ? AchievementsPortableView
      : AchievementsDesktopView;

    return <View
      achievements={items}
      retrieved={retrieved}
      current={current}
      prompts={prompts}
    />
  }
}

export default AchievementsBoard;