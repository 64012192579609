import React from 'react';
import Regular from "../../../../../Engine/Processing/Regular";
import Codes from "../../../../../Engine/Codes";
import PortableLimitResourceCounterView from "../../Portable/PortableLimitResourceCounterView";
import DesktopLimitResourceCounterView from "../../Desktop/DesktopLimitResourceCounterView";

class LimitResourceCounter extends React.Component {
  render () {
    const app = this.props.app;
    const prompts = app.prompts;

    const population = Regular.getPopulationValue(app.state);
    const limit = Regular.getPopulationLimit(app.state);
    const is_visible = app.state['workers'] >= 4;

    const View = app.mode === Codes.modes.portable
      ? PortableLimitResourceCounterView
      : DesktopLimitResourceCounterView;

    return <View
      population={population}
      limit={limit}
      is_visible={is_visible}
      prompts={prompts}
    />
  }
}

export default LimitResourceCounter;