import React from 'react';
import LegacyFeature from "../../../Engine/Features/LegacyFeature";
import PromptBinding from "../PromptBinding";

class PlayerFeatureTile extends React.Component {
  render () {
    const prompts = this.props.prompts;

    const code = this.props.code;
    const level = this.props.level;
    const prompt_key = "prompt-feature-" + this.props.code;
    const prompt_content = LegacyFeature.getDefinition(level);

    const content = (
      <div className={"player-feature-" + code}>
        {level}
      </div>
    );

    return <PromptBinding
      prompts={prompts}
      prompt_key={prompt_key}
      prompt_content={prompt_content}
      content={content}
    />
  }
}

export default PlayerFeatureTile;
