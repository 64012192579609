import CookieManager from "./CookieManager";
import ServerApiManager from "./ServerApiManager";
import {validate} from "uuid";
import GameProgress from "../Entities/GameProgress";

class ProgressManager {
    constructor () {
        this.cookieManager = new CookieManager();
        this.storageManager = new ServerApiManager();

        this.ticks = 0;
    }

    load () {
        const uuid = window.location.pathname.replace('/', '');

        let game = null;

        if (validate(uuid)) {
            this.storageManager.getGameProgress(uuid)
                .then(game => this.cookieManager.save(game))
                .then(() => window.location.replace('/'));
        }

        const data = this.cookieManager.load();

        if (data !== undefined) {
            game = new GameProgress(data["uuid"], data["account"], data["player"], data["state"]);
        }

        return game;
    }

    async init (game) {
        let response = await this.storageManager.initGameProgress(game);

        let json = await response.json();
        let result = GameProgress.init(json);

        this.cookieManager.save(result);

        return result;
    }

    save (game, flow = false) {
        this.cookieManager.save(game);

        if (flow === false || this.ticks >= 50) {
            // this.storageManager.saveGameProgress(game);
            this.ticks = 0;
        } else {
            this.ticks++;
        }
    }

    reset () {
        this.cookieManager.reset();
    }
}

export default ProgressManager;
