import React from 'react';

class DesktopObserversResourceCounterView extends React.Component {
  render () {
    const count = this.props.count;
    const increase = this.props.increase;
    const is_visible = this.props.is_visible;

    return is_visible
      ? <div className={"resource-observers"}>
          <div className={"resource-observers-count"}>
            {count}
          </div>
          <div className={"resource-observers-increase"}>
            {increase}
            <span className={"resource-increase-measure"}>/s</span>
          </div>
        </div>
      : ""
  }
}

export default DesktopObserversResourceCounterView;