import React from 'react';
import ProgressScale from "./ProgressScale";

class ProgressBinding extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      clicks: 0
    }

    this.flow = this.props.flow ?? false;
    this.className = this.props.class ?? "";
    this.type = this.props.type ?? "regular";
    this.color = this.props.color ?? "default";
    this.max_clicks = this.props.scale ?? 10;
    this.disabled = this.props.disabled ?? false;
    this.disposable = this.props.disposable ?? false;

    this.onClick = this.onClick.bind(this);
  }

  onClick () {
    if (this.disabled === false) {
      const clicks = this.state.clicks + 1;
      const onResult = this.props.onResult;

      const onResultFunc = onResult.func;
      const onResultParams = onResult.params;

      if (clicks >= this.max_clicks) {
        if (this.disposable) {
          this.disabled = true;
          this.className += ' display-none';
        }
        onResultFunc(onResultParams);
      } else {
        this.setState({
          clicks: clicks
        })
      }
    }
  }

  render () {
    const content = this.props.content;
    const key = this.props.keyValue ?? 'key';
    const progress_binding_class = this.flow
      ? "progress-bindings-flow"
      : "progress-bindings-static";

    return (
      <div className={this.className} onMouseDown={this.onClick} key={key}>
        {content}
        <div className={progress_binding_class}/>
        <ProgressScale size={this.type} color={this.color} max={this.max_clicks} current={this.state.clicks}/>
      </div>
    );
  }
}

export default ProgressBinding;
