import React from 'react';
import Regular from "../../Engine/Processing/Regular";
import Practical from "../../Engine/Practical";
import DefaultDesktopView from "./Views/DefaultDesktopView";
import Codes from "../../Engine/Codes";
import SkillsHandlers from "../../Engine/Processing/SkillsHandlers";
import DefaultPortableView from "./Views/DefaultPortableView";

class DefaultSector extends React.Component {
  constructor(props) {
    super(props);

    this.mode = props.app.mode;

    this.state = {
      clicks: 0,
      result: {},
    };

    this.onClick = this.onClick.bind(this);
  }

  parameters () {
    return {
      name: "default",
      type: null,
      resources: {},
      need_clicks: 5,
      preview: {},
      requirements: {},
      result: {},
    }
  }

  app () {
    return this.props.app;
  }

  getName () {
    return this.parameters().name;
  }

  getType () {
    return this.parameters().type;
  }

  getNeedClicks () {
    return Number(this.parameters().need_clicks);
  }

  getResource (name) {
    let resources = this.parameters().resources;

    return resources.hasOwnProperty(name)
      ? resources[name]
      : null;
  }

  getResourceCount (name) {
    let resource = this.getResource(name);
    let state = this.app().state;

    return state[resource] ?? 0;
  }

  needClicks () {
      let need_clicks = this.getNeedClicks();
      let app = this.app();

      if (this.getType() === Codes.types.building) {
        need_clicks = SkillsHandlers.handlerBuilderNeedClicks(app.player, need_clicks);

        let specialists_performance = app.settings.performance.specialists.efficiency ?? 0;
        let specialists_increase = Practical.number(app.state.specialists) * specialists_performance;

        need_clicks /= (1 + specialists_increase);
      }

      if (this.getType() === Codes.types.upgrade) {
        need_clicks = SkillsHandlers.handlerUpgraderNeedClicks(app.player, need_clicks);
      }

      return Practical.number(need_clicks, 0);
  }

  getPreview () {
    return this.parameters().preview;
  }

  getRequirements () {
    return this.prepareParameters("requirements");
  }

  getResult () {
    const player = this.app().player;

    let result;
    let type = this.getType();

    if ([Codes.types.building, Codes.types.upgrade].includes(type)) {
      result = this.getRequirements();

      for (let key in result) {
        result[key] *= -1;
      }

      let resource = this.getResource(type);

      result[resource] = 1;

      if (type === Codes.types.building) {
        result = SkillsHandlers.handlerBuilderRawEconomy(player, result);
      }

      if (type === Codes.types.upgrade) {
        result = SkillsHandlers.handlerUpgraderNectarEconomy(player, result);
      }
    } else {
      result = this.prepareParameters("result");

      if (result.hasOwnProperty(Codes.resources.observers)) {
        result = SkillsHandlers.handlerRecruiterObserversRequirement(player, result)
      }

      if (result.hasOwnProperty(Codes.resources.specialists)) {
        result = SkillsHandlers.handlerTrainerSpecialistsCount(player, result)
      }
    }

    return result;
  }

  getClosureBuildingCost (building, price) {
    return function (app) {
      return Regular.getCurrentCost(price, app.state[building]);
    }
  }

  prepareParameters (field) {
    let result = {};
    let parameters = this.parameters()[field];

    if (typeof parameters === "function") {
      result = parameters;
    } else {
      for (let key in parameters) {
        let value = parameters[key];

        if (typeof value === "function") {
          value = value(this.app());
        }

        result[key] = value;
      }
    }

    return result;
  }

  canClicking () {
    return (
      this.getType() !== Codes.types.upgrade ||
      this.getResourceCount(Codes.types.building) > this.getResourceCount(Codes.types.upgrade)
    )
      ? Practical.requirementsAreMet(this.getRequirements(), this.app().state)
      : false;
  }

  onClick () {
    let state = {};

    if (this.canClicking()) {
      let stack_clicks = this.state.clicks + 1;
      let need_clicks = this.needClicks();
      let need_result = stack_clicks >= need_clicks;

      let show_result = (need_clicks - stack_clicks) !== 1;

      if (need_result === true) {
        const result = this.getResult();

        this.props.onResult(result);

        state.clicks = 0;
        state.result = result;
        state.show_result = true;
      } else {
        state.clicks = stack_clicks;
        state.show_result = show_result;
      }
    }

    this.setState(state);
  };

  getSwitcherClass () {
    return 'active';
  }

  views () {
    return {
      "desktop": DefaultDesktopView,
      "portable": DefaultPortableView,
    }
  }

  render () {
    const app = this.app();
    const prompts = app.prompts;
    const onSwitch = this.props.onSwitch;

    let state = app.state;

    let is_available = true;

    for (let preview_key in this.preview) {
      if (state[preview_key] < this.preview[preview_key]) {
        is_available = false;
        break;
      }
    }

    let result = null;

    if (is_available) {
      const views = this.views();
      const View = views[this.mode];
      const switcher_type= onSwitch ? this.getSwitcherClass() : null;

      result = <View
          name={this.getName()}
          type={this.getType()}
          resource={this.getResource(this.getType())}
          preview={this.getPreview()}
          requirements={this.getRequirements()}
          buildings_count={this.getResourceCount(Codes.types.building)}
          upgrades_count={this.getResourceCount(Codes.types.upgrade)}
          active={this.canClicking()}
          need_clicks={this.needClicks()}
          current_clicks={this.state.clicks}
          show_result={this.state.show_result}
          prompts={prompts}
          result={this.state.result}
          onClick={this.onClick}
          onSwitch={onSwitch}
          switcher_type={switcher_type}
      />
    }

    return result;
  }
}

export default DefaultSector;
