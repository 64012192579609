import React from 'react';
import SkillCardPortableItem from "./SkillCardPortableItem";
import BlocksSwitcher from "../Switchers/BlocksSwitcher";

class SkillsChoicePortableView extends React.Component {
  render () {
    const skills = this.props.items;
    const onSkill = this.props.onSkill;

    let items = [];

    for (let n in skills) {
      const item = skills[n];

      items.push(
        <SkillCardPortableItem
          key={n}
          code={item.code}
          level={item.level}
          layer={item.layer}
          is_available={item.is_available}
          onSkill={onSkill}
        />
      );
    }

    return <BlocksSwitcher
      class={"p-stage-end-skills-slider"}
      blocks={items}
    />
  }
}

export default SkillsChoicePortableView;
