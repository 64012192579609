import React from 'react';
import Codes from "../../../../../Engine/Codes";
import PortableStorageProgressCounterView from "../../Portable/PortableStorageProgressCounterView";
import DesktopStorageProgressCounterView from "../../Desktop/DesktopStorageProgressCounterView";

class StorageProgressCounter extends React.Component {
  render () {
    const app = this.props.app;
    const prompts = app.prompts;

    const count = app.state['storage'];
    const uppers = Math.floor(count / 25);
    const percentage = (count % 25) * 4;
    const is_visible = app.state['storage'] >= 1 && app.state['storage'] < 100;

    const View = app.mode === Codes.modes.portable
      ? PortableStorageProgressCounterView
      : DesktopStorageProgressCounterView;

    return <View
      uppers={uppers}
      percentage={percentage}
      prompts={prompts}
      is_visible={is_visible}
    />
  }
}

export default StorageProgressCounter;