import ResourceCounter from "./ResourceCounter";
import Regular from "../../../../../Engine/Processing/Regular";

class WorkersResourceCounter extends ResourceCounter {
  getResource () {
    return 'workers';
  }

  getIncrease () {
    return Regular.efficiency(this.props.app, this.getResource(), 'posts').toFixed(3);
  }

  getVisibleCondition() {
    const app = this.props.app;

    return (
      app.state.workers > 0 ||
      app.state.observers > 15
    );
  }
}

export default WorkersResourceCounter;