import DefaultSector from "../DefaultSector";
import Codes from "../../../Engine/Codes";
import Regular from "../../../Engine/Processing/Regular";
import Practical from "../../../Engine/Practical";
import SkillsHandlers from "../../../Engine/Processing/SkillsHandlers";

class Recruitment extends DefaultSector {
    parameters () {
        return {
            name: 'recruitment',
            type: Codes.types.procreate,
            need_clicks: 16,
            resources: {
                procreate: "workers"
            },
            preview: {
                walls: 1,
            },
            requirements: {
                observers: 20,
            },
            result: {
                observers: -20,
                workers: 1,
            }
        }
    }

    getNeedClicks () {
        return SkillsHandlers.handlerRecruiterNeedClicks(this.app().player, super.getNeedClicks());
    }

    getRequirements () {
        let available_limit = Regular.getAvailablePopulationLimit(this.app().state);

        let requirements = super.getRequirements();

        requirements = SkillsHandlers.handlerRecruiterObserversRequirement(this.app().player, requirements);

        if (available_limit <= 0) {
            requirements["limit"] = Practical.number((available_limit * -1) + 1, 0);
        }

        return requirements;
    }
}

export default Recruitment;