import React from 'react';
import ProgressBinding from "../../Progress/ProgressBinding";

class StageFinishDesktopView extends React.Component {
  render () {
    const onExit = this.props.onExit;

    const content = <div className={"exit-sector"}/>;

    const onResult = {
      "func": onExit,
      "params": {}
    }

    return <ProgressBinding
      class={"exit-board"}
      type={"wide"}
      flow={false}
      color={"choice"}
      content={content}
      onResult={onResult}
      key={'stage_finish_action_board'}
    />
  }
}

export default StageFinishDesktopView;