import React from 'react';
import StageCardTile from "./StageCardTile";
import StageSpecials from "./StageSpecials";

class StageBoard extends React.Component {
  render () {
    const app = this.props.app;
    const stage = app.player.stage;
    const prompts = app.prompts;

    return (
      <div>
        <StageCardTile
          size={"small"}
          stage={stage["code"]}
          level={stage["level"]}
          status={stage["status"]}
          prompts={prompts}
        />
        <StageSpecials app={app}/>
      </div>
    )
  }
}

export default StageBoard;