import React from 'react';

class DesktopResourcesRequirements extends React.Component {
  render () {
    let result = [];

    let resources = this.props.resources ?? {};

    for (let key in resources) {
      let count = resources[key];

      const resource_class = count > 9
        ? 'resource-flow-requirement-long'
        : 'resource-flow-requirement-short';

      result.push(
        <div className={"resource-flow-static"} key={key}>
          <div className={"resource-flow-requirement " + resource_class} key={key}>
            <div className={"resource-flow-image"}>
              <img src={"/images/icons/" + key + ".png"} alt={key} title={key}/>
            </div>
            <div className={"resource-flow-count"}>{count}</div>
          </div>
        </div>
      );
    }

    return result;
  }
}

export default DesktopResourcesRequirements;
