import ResourceCounter from "./ResourceCounter";
import Regular from "../../../../../Engine/Processing/Regular";

class SpecialistsResourceCounter extends ResourceCounter {
  getResource () {
    return 'specialists';
  }

  getPosition() {
    return 'right';
  }

  getIncrease () {
    return Regular.efficiencySpecialistsGrowth(this.props.app).toFixed(3);
  }

  getVisibleCondition() {
    const app = this.props.app;

    return (
      app.state.specialists > 0 ||
      app.state.storage > 0
    );
  }
}

export default SpecialistsResourceCounter;