import ResourceCounter from "./ResourceCounter";
import Regular from "../../../../../Engine/Processing/Regular";
import Practical from "../../../../../Engine/Practical";

class NectarResourceCounter extends ResourceCounter {
  getResource () {
    return 'nectar';
  }

  getPosition() {
    return 'right';
  }

  getIncrease () {
    const app = this.props.app;

    const prodWorkers = Regular.efficiency(app, this.getResource(), 'workers');
    const prodFarms = Regular.efficiency(app, this.getResource(), 'farms');

    return Practical.number(prodWorkers + prodFarms, 4).toFixed(3)
  }

  getVisibleCondition() {
    const app = this.props.app;

    return app.state.storage >= 25;
  }
}

export default NectarResourceCounter;