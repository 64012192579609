import React from 'react';
import Practical from "../../../../Engine/Practical";
import PromptBinding from "../../PromptBinding";

class DesktopStorageProgressCounterView extends React.Component {
  render () {
    const uppers = this.props.uppers;
    const percentage = this.props.percentage;
    const is_visible = this.props.is_visible;
    const prompts = this.props.prompts;

    const prompt_key = "storage-effect-prompt-key";
    const prompt_content = Practical.concatList(
      prompts.get('storage'), <br/>, <br/>, prompts.get('storage-effect-' + uppers)
    );

    let uppers_sector = [];

    for (let n = 1; n <= 3; n++) {
      let upper_type = (n <= uppers)
        ? 'fill'
        : 'empty';

      uppers_sector.push(
        <div className={"storage-progress-upper-" + upper_type}/>
      )
    }

    const style = {
      width: percentage + "%",
    };

    const content = (
      <div className={"storage-progress"}>
        {uppers_sector}
        <div className={"storage-progress-percentage"}>
          <div className={"storage-progress-percentage-active"} style={style}/>
        </div>
      </div>
    );

    return is_visible
      ? <PromptBinding
          prompts={prompts}
          prompt_key={prompt_key}
          prompt_content={prompt_content}
          content={content}
          delay={500}
        />
      : ""
  }
}

export default DesktopStorageProgressCounterView;