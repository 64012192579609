import React from 'react';
import Codes from "../../../Engine/Codes";
import StageFinishPortableView from "./StageFinish/StageFinishPortableView";
import StageFinishDesktopView from "./StageFinish/StageFinishDesktopView";

class StageExitBoard extends React.Component {
  render () {
    const app = this.props.app;
    const onExit = app.onExit;

    let result;

    if (app.player.result.growth <= 0) {
      const View = app.mode === Codes.modes.portable
        ? StageFinishPortableView
        : StageFinishDesktopView;

      result = <View onExit={onExit}/>
    }

    return result;
  }
}

export default StageExitBoard;