import React from 'react';
import PromptBinding from "./PromptBinding";

class StageEndMessagesStaticBoard extends React.Component {
  render () {
    const app = this.props.app;
    const player = app.player;
    const prompts = app.prompts;

    const prompt_key = "stage-end-prompt-key";
    const prompt_content = prompts.get("gameplay-skills");

    const message1 = 'You found queen for your anthill and your job was finished here.';
    const message2 = 'Your level upped to ' + player.result.growth + "!";

    const content = (
      <div className={"messages-level-up"}>
        <div className={"image"}></div>
        <div className={"value-" + player.result.growth}></div>
      </div>
    );

    return (
      <div className={"messages-board"}>
        <div className={"message-plank"}>{message1}</div>
        <div className={"message-plank"}>{message2}</div>
        <PromptBinding
          prompts={prompts}
          prompt_key={prompt_key}
          prompt_content={prompt_content}
          content={content}
        />
      </div>
    );
  }
}

export default StageEndMessagesStaticBoard;
