import React from 'react';
import {TransitionWrapper} from "../../TransitionWrapper";

class DesktopResourcesTransition extends React.Component {
  render () {
    let result = [];

    let resources = this.props.resources ?? {};
    let transition = this.props.transition ?? null;

    if (transition !== null) {
      let items = [];

      for (let key in resources) {
        let count = resources[key];
        let sign;

        if (count < 0) {
          count *= -1;
          sign = "-";
        } else {
          sign = "+";
        }

        items.push(
          <div className={"resource-flow-transition"} key={key}>
            <div className={"resource-flow-sign"}>{sign}</div>
            <div className={"resource-flow-count"}>{count}</div>
            <div className={"resource-flow-image"}>
              <img src={"/images/icons/" + key + ".png"} alt={key} title={key}/>
            </div>
          </div>
        );
      }

      const timeout = 400 + (200 * items.length);

      result = <TransitionWrapper content={items} in_transition={transition} timeout={timeout} class_name={"transition-flow"}/>
    }

    return result;
  }
}

export default DesktopResourcesTransition;
