import React from 'react';
import PromptBinding from "../../PromptBinding";

class PortableStorageProgressCounterView extends React.Component {
  render () {
    const uppers = this.props.uppers;
    const percentage = this.props.percentage;
    const is_visible = this.props.is_visible;
    const prompts = this.props.prompts;

    const prompt_key = 'resource-storage-prompt-key';
    const prompt_content = prompts.get('storage-effect-' + uppers);

    let uppers_sector = [];

    for (let n = 1; n <= 3; n++) {
      let upper_type = (n <= uppers)
        ? 'fill'
        : 'empty';

      uppers_sector.push(
        <div className={"p-storage-progress-upper-" + upper_type}/>
      )
    }

    const style = {
      width: percentage + "%",
    };

    const content = (
      <div className={"p-storage-progress"}>
        <div className={"p-storage-progress-uppers"}>
          {uppers_sector}
        </div>
        <div className={"p-storage-progress-percentage"}>
          <div className={"p-storage-progress-percentage-active"} style={style}/>
        </div>
      </div>
    );

    return is_visible
      ? <PromptBinding
          prompts={prompts}
          prompt_key={prompt_key}
          prompt_content={prompt_content}
          content={content}
          place={"top-end"}
        />
      : ""
  }
}

export default PortableStorageProgressCounterView;