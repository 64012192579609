import React from 'react';
import DataManager from "../../Engine/Managers/DataManager";
import PlayerProgressCode from "../Components/Player/PlayerProgressCode";
import CopyrightLine from "../Components/General/CopyrightLine";
import StageChoiceBoard from "../Components/Stages/StageChoice/StageChoiceBoard";
import PortablePlayerLevelButton from "../Components/Properties/PortablePlayerLevelButton";
import PortableProductionProperty from "../Components/Properties/PortableProductionProperty";
import PortableStageFeatureProperty from "../Components/Properties/PortableStageFeatureProperty";
import PlayerStageBoard from "../Components/PlayerStageBoard";
import Practical from "../../Engine/Practical";

class GateFlowDesk extends React.Component {
  render () {
    let app = this.props.app;

    const general_messages = DataManager.getDescriptions(DataManager.DSC_TYPE_GENERAL);
    const stages_title_message = general_messages["gate-choice-title"];
    const prompts = app.prompts;

    const expandedPropertiesClass = (app.state['process']['is_open_player_board'] ?? false)
      ? 'p-stage-properties--expanded'
      : 'p-stage-properties--collapsed';

    let legend_parts = [];

    if (app.uuid !== null) {
      const story = DataManager.getDescriptionValue(DataManager.DSC_TYPE_STORY, "1");

      legend_parts.push(story[0] ?? prompts.get("empty"));
      legend_parts.push(<br/>);
      legend_parts.push(<PlayerProgressCode uuid={app.uuid ?? null} prompts={prompts}/>);
    } else {
      const story = DataManager.getDescriptionValue(DataManager.DSC_TYPE_STORY, "0");

      legend_parts.push(story[0] ?? prompts.get("empty"));
      legend_parts.push(<br/>);
      legend_parts.push(story[1] ?? prompts.get("empty"));
    }

    const gate_legend = Practical.concatList(...legend_parts);

    const version = DataManager.getVersion();

    return (
      <div className={"gate-flow-desk"}>
        <div className={"p-zone-header"}>
          <div className={"p-logo"}/>
        </div>
        <div className={"p-zone-stage-properties " + expandedPropertiesClass}>
          <div className={"p-zone-stage-properties-edge-left"}>
            <PortablePlayerLevelButton app={app}/>
          </div>
          <div className={"p-zone-stage-properties-edge-middle"}/>
          <div className={"p-zone-stage-properties-edge-right"}>
            <PortableProductionProperty app={app}/>
            <PortableStageFeatureProperty app={app}/>
          </div>
        </div>
        <PlayerStageBoard app={app}/>
        <div className={"p-zone-gate-story"}>
          <div className={"p-gate-story"}>
            {gate_legend}
          </div>
        </div>
        <div className={"p-zone-gate-stages"}>
          <div className={"p-gate-stages-title"}>
            {stages_title_message}
          </div>
          <StageChoiceBoard app={app}/>
        </div>
        <div className={"p-zone-footer"}>
          <div className={"p-ground"}>
            <CopyrightLine/> | v{version} (test)
          </div>
        </div>
      </div>
    );
  }
}

export default GateFlowDesk;
