import DefaultSector from "../DefaultSector";
import Codes from "../../../Engine/Codes";
import SkillsHandlers from "../../../Engine/Processing/SkillsHandlers";

class WoodRaw extends DefaultSector {
    parameters () {
        return {
            name: 'wood',
            type: Codes.types.collect,
            need_clicks: 8,
            resources: {},
            result: {
                raw: 1,
            }
        }
    }

    getNeedClicks () {
        return SkillsHandlers.handlerCollectorNeedClicks(this.app().player, super.getNeedClicks());
    }

    getResult () {
        let result = super.getResult();

        result = SkillsHandlers.handlerCollectorRawCount(this.app().player, result);
        result = SkillsHandlers.handlerInviterObserversCount(this.app().player, result);

        return result;
    }

    getSwitcherClass () {
        return 'raw';
    }
}

export default WoodRaw;