import React from 'react';
import DataManager from "../../../Engine/Managers/DataManager";
import Practical from "../../../Engine/Practical";
import PromptBinding from "../PromptBinding";

class PlayerSkillTile extends React.Component {
  render () {
    const prompts = this.props.prompts;
    const code = this.props.code;
    const level = this.props.level;
    const prompt_key = "prompt-skill-prompt-" + this.props.code;

    const title = DataManager.getDescriptions(DataManager.DSC_TYPE_SKILLS)[code] ?? '';

    const prompt_content = Practical.concatList(
      DataManager.handleContent("%%" + title + ":TITLE%%"),
      DataManager.getSkillDefinition(code, level)
    );

    const content = (
      <div className={"player-skill-" + code}>
        {level}
      </div>
    );

    return <PromptBinding
      prompts={prompts}
      prompt_key={prompt_key}
      prompt_content={prompt_content}
      content={content}
    />
  }
}

export default PlayerSkillTile;
