import DataManager from "./DataManager";

class PromptsState {
  constructor (onPrompt) {
    this.data = DataManager.getPrompts();
    this._actual = null;
    this._onPrompt = onPrompt;
  }

  get (code) {
    return this.data[code] ?? this.data["empty"];
  }

  actual () {
    return this._actual;
  }

  setActual (value) {
    this._actual = value;
  }

  onPrompt () {
    return this._onPrompt;
  }
}

export default PromptsState;
  