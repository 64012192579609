import React from 'react';
import Codes from "../../Engine/Codes";

class DisableSector extends React.Component {
  render () {
    const name = this.props.name;
    const mode = this.props.app.mode;

    return mode === Codes.modes.portable
        ?
        <div className={"p-sector"}>
          <div className={"p-sector-specific p-sector-specific-" + name + "-hidden"}/>
        </div>
        :
        <div className={"sector"}>
          <div className={"sector-specific sector-specific-" + name + "-hidden"}/>
        </div>
  }
}

export default DisableSector;
