import React from 'react';
import DesktopResourceCounterView from "../../Desktop/DesktopResourceCounterView";
import Codes from "../../../../../Engine/Codes";
import PortableResourceCounterView from "../../Portable/PortableResourceCounterView";

class ResourceCounter extends React.Component {
  getResource () {
    return this.props.resource ?? '';
  }

  getCount () {
    const app = this.props.app;

    return app.state[this.getResource()];
  }

  getIncrease () {
    return Number(0).toFixed(2);
  }

  getVisibleCondition () {
    return true;
  }

  getPosition () {
    return 'left';
  }

  render () {
    const app = this.props.app;
    const View = app.mode === Codes.modes.portable
      ? PortableResourceCounterView
      : DesktopResourceCounterView;

    const prompts = app.prompts;

    return <View
      resource={this.getResource()}
      count={this.getCount()}
      increase={this.getIncrease()}
      is_visible={this.getVisibleCondition()}
      position={this.getPosition()}
      prompts={prompts}
    />;
  }
}

export default ResourceCounter;