import React from 'react';
import DataManager from "../../../Engine/Managers/DataManager";
import Practical from "../../../Engine/Practical";
import PromptBinding from "../PromptBinding";

class AchievementPortableItem extends React.Component {
  render () {
    const code = this.props.code;
    const status_class_postfix = (this.props.is_retrieved === true)
      ? 'done'
      : 'process';

    const prompts = this.props.prompts;
    const prompt_key = "achievement-" + code + "-prompt-key";

    const title = DataManager.getDescriptionValue(DataManager.DSC_TYPE_ACHIEVEMENTS, code);
    const prompt_content = Practical.concatList(
      DataManager.handleContent("%%" + title + ":TITLE%%"),
      DataManager.getAchievementDefinition(code)
    );

    const content = (
      <div className={"p-stage-end-achievement-item"}>
        <div className={"p-stage-end-achievement-item-image p-stage-end-achievement-item-image--"  + code}/>
        <div className={"p-stage-end-achievement-item-status p-stage-end-achievement-item-status--" + status_class_postfix}/>
      </div>
    );

    return <PromptBinding
      prompts={prompts}
      prompt_key={prompt_key}
      prompt_content={prompt_content}
      content={content}
    />
  }
}

export default AchievementPortableItem;