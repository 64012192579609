import React from 'react';
import PromptBinding from "../../PromptBinding";
import Practical from "../../../../Engine/Practical";

class PortableLimitResourceCounterView extends React.Component {
  render () {
    const population = this.props.population;
    const limit = this.props.limit;
    const is_visible = this.props.is_visible;

    const prompts = this.props.prompts;
    const prompt_key = 'resource-limit-prompt-key';
    const prompt_content = Practical.concatList(prompts.get('tunnel'), <br/>, <br/>, prompts.get('population-limit'));

    const content = (
      <div className={"p-resource-limit"}>
        {population} / {limit}
      </div>
    );

    return is_visible
      ? <PromptBinding
          prompts={prompts}
          prompt_key={prompt_key}
          prompt_content={prompt_content}
          content={content}
        />
      : ""
  }
}

export default PortableLimitResourceCounterView;