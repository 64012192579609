class Codes {
  static modes = {
    desktop: "desktop",
    portable: "portable",
  }

  static types = {
    collect: "collect",
    procreate: "procreate",
    building: "building",
    upgrade: "upgrade",
  };

  static resources = {
    raw: "raw",
    nectar: "nectar",
    observers: "observers",
    workers: "workers",
    specialists: "specialists",
    walls: "walls",
    tunnels: "tunnels",
    storage: "storage",
    posts: "posts",
    farms: "farms",
    special: "special",
  }

  static skills = {
    collector: "collector",
    inviter: "inviter",
    builder: "builder",
    recruiter: "recruiter",
    trainer: "trainer",
    upgrader: "upgrader",
    seeker: "seeker",
    promoter: "promoter",
    chief: "chief",
  }

  static stages = {
    classic: "classic",
    few_raw: "few-raw",
    few_observers: "few-observers",
    high_cleaning: "high-cleaning",
  }
}

export default Codes;
