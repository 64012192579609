import React from 'react';

class PortableSpecialProgressCounterView extends React.Component {
  render () {
    const chance = this.props.chance;
    const acceleration = this.props.acceleration;
    const is_visible = this.props.is_visible;

    return is_visible
      ? <div className={"p-queen-progress"} id={"storage-effect-id"}>
        <div className={"p-queen-progress-value"}>
          {acceleration}
          <span className={"p-queen-progress-percent"}>%</span>
        </div>
        <div className={"p-queen-progress-value"}>
          {chance}
          <span className={"p-queen-progress-percent"}>%</span>
        </div>
      </div>
      : ""
  }
}

export default PortableSpecialProgressCounterView;