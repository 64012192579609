import React from 'react';
import PromptBinding from "../../PromptBinding";

class DesktopSpecialProgressCounterView extends React.Component {
  render () {
    const chance = this.props.chance;
    const acceleration = this.props.acceleration;
    const is_visible = this.props.is_visible;
    const prompts = this.props.prompts;

    const prompt_key = "storage-effect-prompt-key";
    const prompt_content = prompts.get('storage-effect-4');

    const content = (
      <div className={"queen-progress"}>
        <div className={"queen-progress-value"}>
          {acceleration}
          <span className={"queen-progress-percent"}>%</span>
        </div>
        <div className={"queen-progress-value"}>
          {chance}
          <span className={"queen-progress-percent"}>%</span>
        </div>
      </div>
    );

    return is_visible
      ? <PromptBinding
          prompts={prompts}
          prompt_key={prompt_key}
          prompt_content={prompt_content}
          content={content}
          delay={1200}
        />
      : ""
  }
}

export default DesktopSpecialProgressCounterView;