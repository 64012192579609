import React from 'react';
import Practical from "../../../Engine/Practical";
import Player from "../../../Engine/Entities/Player";
import PromptBinding from "../PromptBinding";

class PortableProductionProperty extends React.Component {
  render () {
    const app = this.props.app;
    const remain = app.state.process.remain ?? 0;
    const player = app.player;

    const prompts = app.prompts;
    const prompt_key = "gameplay-processing-prompt-id";

    let status;
    let step;
    let prompt_content;

    if (player.status === Player.STATUS_PLAY_STAGE) {
      if (remain <= 0) {
        status = "sleep";
      } else if (remain <= 4) {
        status = "wait";
      } else {
        status = "active";
      }

      step = (status !== "sleep")
        ? (Practical.number(Date.now() / 1000, 0) % 4) + 1
        : 0;

      prompt_content = prompts.get("process");
    } else {
      status = "finish";
      step = 5;
      prompt_content = prompts.get("process-wait");
    }

    const className = "p-stage-process-property p-stage-process-property-status-" + status + "--circle-" + step;
    const content = <div className={className}/>

    return <PromptBinding
      prompts={prompts}
      prompt_key={prompt_key}
      prompt_content={prompt_content}
      content={content}
    />
  }
}

export default PortableProductionProperty;
