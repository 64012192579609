import React from 'react';
import AntiClicker from "./AntiClicker";
import {createRoot} from "react-dom/client";

const root = createRoot(document.getElementById('root'));
const device = window.screen.width >= 600 && window.screen.height >= 430
    ? "desktop"
    : "portable";

root.render(<AntiClicker device={device}/>);
