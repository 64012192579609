import React from 'react';
import SkillsSummarizer from "../../../Engine/Processing/SkillsSummarizer";
import Codes from "../../../Engine/Codes";
import SkillsChoiceDesktopView from "./SkillsChoiceDesktopView";
import SkillsChoicePortableView from "./SkillsChoicePortableView";

class SkillsChoiceBoard extends React.Component {
  render () {
    const app = this.props.app;
    const player = app.player;
    const onSkill = app.onSkill;

    let render;

    if (player.result.growth > 0) {
      const items = SkillsSummarizer.getSelectableSkills(player);

      const View = app.mode === Codes.modes.portable
        ? SkillsChoicePortableView
        : SkillsChoiceDesktopView;

      render = <View
        items={items}
        onSkill={onSkill}
      />
    }

    return render;
  }
}

export default SkillsChoiceBoard;
