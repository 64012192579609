import React from 'react';
import ProgressBinding from "../../Progress/ProgressBinding";

class StageFinishPortableView extends React.Component {
  render () {
    const onExit = this.props.onExit;

    const content = <div className={"p-stage-end-finish-action-card"}/>;

    const onResult = {
      "func": onExit,
      "params": {}
    }

    return <ProgressBinding
      class={"p-stage-end-finish-action"}
      type={"regular"}
      flow={true}
      color={"choice"}
      content={content}
      onResult={onResult}
      key={'stage_finish_action_board'}
    />
  }
}

export default StageFinishPortableView;