import DefaultSector from "../DefaultSector";
import Codes from "../../../Engine/Codes";
import Regular from "../../../Engine/Processing/Regular";
import Practical from "../../../Engine/Practical";
import SkillsHandlers from "../../../Engine/Processing/SkillsHandlers";

class Training extends DefaultSector {
    parameters () {
        return {
            name: 'training',
            type: Codes.types.procreate,
            need_clicks: 16,
            resources: {
                procreate: "specialists"
            },
            preview: {
                storage: 50,
            },
            requirements: {
                workers: 1,
                nectar: 3,
            },
            result: {
                workers: -1,
                nectar: -3,
                specialists: 1,
            }
        }
    }

    getNeedClicks () {
        return SkillsHandlers.handlerTrainerNeedClicks(this.app().player, super.getNeedClicks());
    }

    getRequirements () {
        let app = this.props.app;
        let available_limit = Regular.getAvailablePopulationLimit(app.state);

        let requirements = super.getRequirements();

        if (available_limit <= 0) {
            requirements["limit"] = Practical.number((available_limit * -1) + 1, 0);
        }

        return requirements;
    }
}

export default Training;