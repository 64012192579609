import React from 'react';
import PromptBinding from "../../PromptBinding";

class PortableResourceCounterView extends React.Component {
  render () {
    const prompts = this.props.prompts;
    const resource = this.props.resource;
    const count = this.props.count;
    const increase = this.props.increase;
    const is_visible = this.props.is_visible;
    const position = this.props.position

    const prompt_key = 'p-resource-counter-block-prompt-' + resource;
    const prompt_content = prompts.get(resource);

    const prompt_place = position === "right" ? "top-end" :  "top-start";
    const position_class = "p-resource-position-" + position;

    let content;

    if (is_visible) {
      content = <div>
        <div className={"p-resources-counter p-resources-counter-" + position}>
          <div className={"p-resource-icon " + position_class}>
            <img src={"/images/icons/" + resource + ".png"} alt={resource} title={resource}/>
          </div>
          <div className={"p-resource-count " + position_class}>
            {count}
          </div>
          <div className={"p-resource-increase " + position_class}>
            {increase}
            <span className={"p-resource-increase-measure"}>/s</span>
          </div>
        </div>
      </div>

      content = <PromptBinding
        prompts={prompts}
        prompt_key={prompt_key}
        prompt_content={prompt_content}
        place={prompt_place}
        content={content}
      />
    } else {
      content = <div className={"p-resources-counter p-resources-counter-" + position + "-disabled"}/>;
    }

    return content;
  }
}

export default PortableResourceCounterView;