import DefaultSector from "../DefaultSector";
import Codes from "../../../Engine/Codes";

class Post extends DefaultSector {
    parameters () {
        return {
            name: 'post',
            type: Codes.types.upgrade,
            resources: {
                building: "walls",
                upgrade: "posts",
            },
            need_clicks: 10,
            preview: {
                storage: 25,
            },
            requirements: {
                raw: this.getClosureBuildingCost("posts", 6),
                nectar: 3,
                specialists: 1,
            },
        }
    }
}

export default Post;