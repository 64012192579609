import React from 'react';
import Player from "../Engine/Entities/Player";
import GateStaticDesk from "../Display/Desks/GateStaticDesk";
import StageStatusStaticDesk from "../Display/Desks/StageStatusStaticDesk";
import StagePlayStaticDesk from "../Display/Desks/StagePlayStaticDesk";
import StageGroundStaticDesk from "../Display/Desks/StageGroundStaticDesk";
import StageEndStaticDesk from "../Display/Desks/StageEndStaticDesk";

class DesktopController extends React.Component {
    render () {
        let app = this.props.app;

        const desks = [];
        const player_status = app.player.status;

        switch (player_status) {
            case Player.STATUS_GATE:
                desks.push(<GateStaticDesk app={app}/>);
                break;

            case Player.STATUS_PLAY_STAGE:
                desks.push(<StageStatusStaticDesk app={app}/>);
                desks.push(<StagePlayStaticDesk app={app}/>);
                desks.push(<StageGroundStaticDesk app={app}/>);
                break;

            case Player.STATUS_FINISH_STAGE:
                desks.push(<StageStatusStaticDesk app={app}/>);
                desks.push(<StageEndStaticDesk app={app}/>);
                desks.push(<StageGroundStaticDesk app={app}/>);
                break;

            default:
                break;
        }

        let window_class = player_status === Player.STATUS_GATE
            ? "window-gate"
            : "window-stage";

        return (
            <div className={'window-desktop ' + window_class}>
                {desks}
            </div>
        );
    }
}

export default DesktopController;
